import styled from 'styled-components/macro';

export const OptionsContainer = styled.div`
  max-height: 13.3rem;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;
