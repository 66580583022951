import { CreateSubscriptionTheme } from 'themes/types/themeApp/components/createSubscription.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: CreateSubscriptionTheme = {
  subscriptionTypes: {
    pro: {
      steps: {
        components: {
          reviewAndCreate: {
            components: {
              costsAndPayment: {
                costsAndPayment: {
                  hr: {
                    backgroundColor: themeUi.semantic.color.background.neutral500
                  },
                  explanationText: {
                    color: themeUi.semantic.color.text.neutral600
                  }
                },

                components: {
                  applyCoupon: {
                    couponContainer: {
                      backgroundColor: themeUi.semantic.color.background.neutral100,
                      backgroundColorChild: themeUi.semantic.color.background.neutral400
                    },
                    couponTitle: {
                      color: themeUi.semantic.color.text.neutral800
                    }
                  },

                  blockTitle: {
                    hr: {
                      backgroundColor: themeUi.semantic.color.background.neutral500
                    }
                  },

                  totalSubPrice: {
                    darkBlueTextColor: {
                      color: themeUi.semantic.color.text.neutral800
                    },
                    perMonthText: {
                      color: themeUi.semantic.color.text.neutral600
                    }
                  }
                }
              },

              databasesTable: {
                wrappedTable: {
                  borderTop: themeUi.semantic.color.border.neutral300
                }
              },

              subscriptionProperties: {
                propertyRow: {
                  color: themeUi.semantic.color.text.neutral700
                }
              },

              vendorDetailsAndName: {
                leftSection: {
                  backgroundColor: themeUi.semantic.color.background.neutral400
                },
                inputInnerContainer: {
                  backgroundColor: themeUi.semantic.color.background.neutral400
                },
                sharedLabelsStyle: {
                  color: themeUi.semantic.color.text.neutral700
                },
                valueText: {
                  color: themeUi.semantic.color.text.neutral800
                }
              }
            }
          },

          setup: {
            components: {
              advancedOptions: {
                components: {
                  cloudAccountSelect: {
                    title: {
                      color: themeUi.semantic.color.text.neutral700
                    }
                  },

                  multiAz: {
                    title: {
                      color: themeUi.semantic.color.text.neutral800
                    },
                    readMore: {
                      color: themeUi.semantic.color.text.primary600
                    }
                  },

                  vpcConfiguration: {
                    title: {
                      color: themeUi.semantic.color.text.neutral800
                    },
                    divider: {
                      backgroundColor: themeUi.semantic.color.background.neutral400
                    }
                  }
                }
              },

              multiSelectRegions: {
                regionDisplayField: {
                  color: themeUi.semantic.color.text.primary600
                }
              }
            }
          },

          sizing: {
            sizing: {
              wrappedTable: {
                borderTop: themeUi.semantic.color.border.neutral300
              }
            },

            components: {
              newDatabaseForm: {
                newDatabaseForm: {
                  divider: {
                    backgroundColor: themeUi.semantic.color.background.neutral400
                  }
                },

                components: {
                  optimizeProModal: {
                    optimizedValuesTitle: {
                      color: themeUi.semantic.color.text.neutral800
                    }
                  },

                  aaForm: {
                    hrDark: {
                      borderTop: themeUi.semantic.color.border.neutral500
                    }
                  },

                  advancedCapabilities: {
                    headerNoticeMessage: {
                      color: themeUi.semantic.color.text.neutral600,
                      fontSize: themeUi.core.font.fontSize.s12
                    },
                    recommendedText: {
                      color: themeUi.semantic.color.text.neutral700,
                      fontSize: themeUi.core.font.fontSize.s14
                    },
                    advancedCapabilitiesTitle: {
                      color: themeUi.semantic.color.text.neutral800,
                      fontSize: themeUi.core.font.fontSize.s16
                    }
                  },
                  midSection: {
                    hashingPolicy: {
                      hashingPolicyConfiguration: {
                        additionalInfo: {
                          color: themeUi.semantic.color.text.neutral600
                        },
                        tooltip: {
                          color: themeUi.semantic.color.text.neutral800
                        }
                      },
                      components: {
                        hashingPolicySelection: {
                          popover: {
                            color: themeUi.semantic.color.text.neutral800
                          }
                        }
                      }
                    }
                  },

                  moreOptions: {
                    verticalDivider: {
                      backgroundColor: themeUi.semantic.color.background.neutral400
                    }
                  }
                }
              }
            }
          }
        }
      },

      components: {
        readMoreAboutCloudAccount: {
          link: {
            color: themeUi.semantic.color.text.neutral100
          }
        }
      }
    }
  },

  components: {
    cloudAndRegionSelection: {
      components: {
        creationRegionSelect: {
          creationRegionSelect: {
            title: {
              color: themeUi.semantic.color.text.neutral700
            },
            selectContentBottom: {
              borderTop: themeUi.semantic.color.border.neutral400
            }
          },

          components: {
            renderRegionItem: {
              optInText: {
                color: themeUi.semantic.color.text.neutral600
              }
            }
          }
        }
      }
    }
  }
};

export default theme;
