import styled, { css } from 'styled-components/macro';
import { transformToRem } from './utils/dimensions';

const lightBlue6 = '#F7FAFF';

export const TABLE_ROW_HEIGHT = 56;

export const Container = styled.div`
  width: 100%;
  border-radius: 0.8rem;
  background-color: ${({ theme }) =>
    theme.appComponents.shared.table.table.container.backgroundColor};
  box-shadow: ${({ theme }) => theme.appComponents.shared.table.table.container.boxShadow};
  overflow: hidden;
`;

export const Table = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  min-width: 74.5rem !important;
`;

export const Thead = styled.div`
  border-bottom-width: 0.1rem;
`;

export const Tbody = styled.div<{ tableBodyHeight: number }>`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${({ tableBodyHeight }) => tableBodyHeight}px;
`;

export const tdStyle = css`
  &:not(:first-child):not(:last-child) {
    padding: 0 0.75rem;
  }
  &:first-child {
    padding: 0 0.75rem 0 1.6rem;
  }
  &:last-child {
    padding: 0 1.6rem 0 0.75rem;
  }
`;

export const Th = styled.div<{ isActionRow: boolean }>`
  color: ${({ theme }) => theme.appComponents.shared.table.table.th.color};
  font-weight: 600;
  vertical-align: bottom;
  white-space: nowrap;
  width: auto;
  height: ${transformToRem(TABLE_ROW_HEIGHT)}rem;
  &:not(:first-child) {
    ${({ isActionRow }) =>
      !isActionRow &&
      css`
        background: linear-gradient(
          to left,
          transparent calc(100% - 0.1rem),
          ${({ theme }) => theme.appComponents.shared.table.table.th.backgroundColor} 50%
        );
        background-size: 100% 3.6rem;
        background-repeat: no-repeat;
        background-position: right;
      `}
  }
  ${tdStyle};
`;

export const Tr = styled.div<{ rowData?: { isNewRow?: string } }>`
  ${({ rowData }) => rowData?.isNewRow && `background-color: ${lightBlue6};`}
  transition: background-color 130ms ease-in-out, box-shadow 130ms ease-in-out;
  height: ${transformToRem(TABLE_ROW_HEIGHT)}rem;
  &:not(:first-child) {
    border-top-width: 0.1rem;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0.4rem 0 1.1rem;
  }
`;

export const Td = styled.div`
  ${tdStyle};
`;

export const ArrowIndicator = styled.div<{ isSortedDesc: boolean; isSorted: boolean }>`
  width: unset !important; // overrides react-table default width
  min-width: unset !important;
  flex: 0 !important;
  opacity: ${({ isSorted }) => (isSorted ? 1 : 0)};
  transform-origin: center;
  display: flex;
  ${({ isSortedDesc }) =>
    isSortedDesc &&
    css`
      transform: rotate(180deg);
    `};
`;

export const ThContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    /* for all the sort/filter svg icons in the header */
    && * {
      opacity: 1;
    }
  }
`;

export const TableExplanationText = styled.div`
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;
