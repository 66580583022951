import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react';

export interface Indicator {
  type: 'success' | 'warning';
  text: string;
}

export interface DialogIndicatorContextValue {
  indicator: Indicator | null;
  setIndicator: Dispatch<SetStateAction<Indicator | null>>;
}

type DialogIndicatorContextGlobal = Partial<Pick<DialogIndicatorContextValue, 'setIndicator'>>;

export const dialogIndicatorContextGlobal: DialogIndicatorContextGlobal = {};

const DialogIndicatorContext = createContext({} as DialogIndicatorContextValue);

export const DialogIndicatorContextProvider = (props: Required<PropsWithChildren<unknown>>) => {
  const [indicator, setIndicator] = useState<Indicator | null>(null);
  const contextValue = useMemo(() => ({ indicator, setIndicator }), [indicator]);
  dialogIndicatorContextGlobal.setIndicator = setIndicator;

  return <DialogIndicatorContext.Provider {...props} value={contextValue} />;
};

export const useDialogIndicatorContext = () => useContext(DialogIndicatorContext);
