import { TabsTheme } from 'themes/types/themeApp/components/tabs.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: TabsTheme = {
  tabList: {
    borderBottom: `1px solid ${themeUi.semantic.color.border.neutral600}`,
    secondary: {
      borderTop: themeUi.semantic.color.border.primary100,
      borderInlineEnd: themeUi.semantic.color.border.primary100
    }
  },
  link: {
    selectedBorderBottom: `2px solid ${themeUi.semantic.color.border.primary600}`,
    color: themeUi.semantic.color.text.neutral600,
    hoverColor: themeUi.semantic.color.text.primary600,
    selectedColor: themeUi.semantic.color.text.primary600
  }
};

export default theme;
