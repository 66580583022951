import styled from 'styled-components/macro';
import Table from '../../Table';
import { TableRowButtonsContainer } from './TableRowButtons/TableRowButtons.style';
import { ActionButton } from './TableRowSingleButton/TableRowSingleButton.style';
import { Tr } from '../../Table.style';

// TODO: aici am ramas!
// TODO: am nevoie sa termin table ca sa pot continua cu restul
export const HoverableTable = styled(Table)`
  ${Tr}:hover {
    ${TableRowButtonsContainer}, ${ActionButton} {
      opacity: 1;
      visibility: visible;
    }
  }
`;
