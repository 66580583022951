/* eslint-disable import/no-cycle */
import React from 'react';
import { ResetIcon } from '@redislabsdev/redis-ui-icons';
import { Button, Popover, TextButton } from '@redislabsdev/redis-ui-components';
import OptionCheckboxes from './OptionCheckboxes/OptionCheckboxes';
import { ContentContainer } from '../../sharedStyle';

import * as S from './ColumnOptionsFilter.style';
import CellFilter from '../CellFilter/CellFilter';

export interface IOption {
  [key: string]: { label: string; checked: boolean };
}

const ColumnOptionsFilter = ({ column: { initialState, filterValue, setFilter } }) => {
  const [options, setOptions] = React.useState<IOption>(initialState);
  const isSomeOptionSelected = Object.keys(options).some((optionKey) => options[optionKey].checked);

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      if (filterValue === undefined) {
        setOptions(initialState);
      } else {
        const newOptions = { ...options };
        filterValue.forEach(
          // eslint-disable-next-line no-return-assign
          (optionKey) =>
            (newOptions[optionKey] = {
              ...newOptions[optionKey],
              checked: true
            })
        );
        setOptions(newOptions);
      }
    }
  };

  const handleCheckboxChange = ({
    target: { name, checked }
  }: React.ChangeEvent<HTMLInputElement>) => {
    const item = options[name];
    setOptions({
      ...options,
      [name]: { ...item, checked }
    });
  };

  const handleApplyClick = () => {
    const selectedOptions = Object.keys(options).filter((optionKey) => options[optionKey].checked);
    setFilter(!selectedOptions.length ? undefined : selectedOptions);
  };

  const handleClearFilter = (e) => {
    e.stopPropagation();
    setOptions({ ...initialState });
  };

  return (
    <CellFilter onVisibilityChange={handleVisibilityChange} isFilterActive={!!filterValue}>
      {(handleCloseOpen) => (
        <ContentContainer>
          <OptionCheckboxes options={options} onChange={handleCheckboxChange} />
          <S.Divider />
          <Popover.Card.Footer.Compose>
            <TextButton
              onClick={handleClearFilter}
              disabled={!isSomeOptionSelected}
              data-testid="clear-filter"
            >
              <Button.Icon icon={ResetIcon} />
              Clear
            </TextButton>
            <S.ButtonsContainer>
              <Button variant="secondary-ghost" onClick={handleCloseOpen}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleApplyClick();
                  handleCloseOpen();
                }}
              >
                Apply
              </Button>
            </S.ButtonsContainer>
          </Popover.Card.Footer.Compose>
        </ContentContainer>
      )}
    </CellFilter>
  );
};

export default ColumnOptionsFilter;
