import styled from 'styled-components/macro';

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 0.6rem;
`;

export const Divider = styled.hr`
  width: 27.7rem;
  height: 1px;
  background-color: ${({ theme }) =>
    theme.appComponents.shared.table.components.columnOptionsFilter.divider.backgroundColor};
  margin: 1.6rem -1.6rem 0;
`;
