import React, { useEffect, useState } from 'react';
import { Popover } from '@redislabsdev/redis-ui-components';
import SvgFilterIcon from '../SvgFilterIcon/SvgFilterIcon';

interface Props {
  onVisibilityChange: (isVisible: boolean) => void;
  children: (toggleIsVisible: () => void) => React.ReactNode;
  isFilterActive: boolean;
}

const CellFilter = ({ onVisibilityChange, children, isFilterActive }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleIsVisible = () => setIsVisible((prevIsVisible) => !prevIsVisible);

  useEffect(() => {
    onVisibilityChange(isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <Popover
      placement="bottom"
      textColor="neutral800"
      open={isVisible}
      onOpenChange={(newIsVisible) => setIsVisible(newIsVisible)}
      content={isVisible ? children(toggleIsVisible) : ''}
      withButton
      data-testid="popover-cell-filter"
    >
      <SvgFilterIcon isFilterActive={isFilterActive} />
    </Popover>
  );
};

export default CellFilter;
