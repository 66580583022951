import styled from 'styled-components/macro';

export const Title = styled.h1`
  font-weight: 700;
  color: ${({ theme }) => theme.appComponents.pagesTitle.color};
  display: block;
  margin-left: -0.2rem;
  text-transform: capitalize;
`;

export const TitleContainer = styled.div`
  margin-bottom: 5vh;
`;
