import { ActionIconButton, Button } from '@redislabsdev/redis-ui-components';
import styled from 'styled-components/macro';

export const TableRowButtonsContainer = styled.div<{ editMode?: boolean }>`
  display: flex;
  justify-content: flex-end;
  opacity: ${({ editMode }) => (editMode ? 1 : 0)};
  visibility: ${({ editMode }) => (editMode ? 'visible' : 'hidden')};
  transition: 0.5s;
  width: 100%;
`;

export const ActionSubmitButton = styled(ActionIconButton)`
  margin-right: 0.6rem;
  min-width: unset;
`;

export const ActionCancelButton = styled(ActionIconButton)`
  margin-right: 1rem;
  min-width: unset;
`;

export const SubmitButton = styled(Button)`
  margin-right: 0.6rem;
`;

export const CancelButton = styled(Button)`
  margin-right: 1rem;

  :hover {
    color: ${({ theme }) =>
      theme.appComponents.shared.table.components.tableWithActionRows.tableRowButtons.cancelButton
        .hoverColor};
  }
  :disabled {
    background-color: ${({ theme }) =>
      theme.appComponents.shared.table.components.tableWithActionRows.tableRowButtons.cancelButton
        .disabledBackgroundColor};
    color: ${({ theme }) =>
      theme.appComponents.shared.table.components.tableWithActionRows.tableRowButtons.cancelButton
        .disabledColor};
  }
  :active {
    color: ${({ theme }) =>
      theme.appComponents.shared.table.components.tableWithActionRows.tableRowButtons.cancelButton
        .activeColor};
  }
`;
