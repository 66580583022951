/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { FilterTypes } from 'react-table';
import styled from 'styled-components/macro';
import { TableCellProps } from './Table.types';

export const numOfRowsPerPageOptions = [5, 10, 20, 50, 100];

// Define a default UI for filtering
export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export const filterTypes = {
  text: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id as unknown as string];

      return rowValue !== undefined
        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
  // filterValue must be in the shape of rowValue
  options: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id as unknown as string];

      return rowValue !== undefined
        ? rowValue.some((option) => filterValue.includes(option))
        : true;
    });
  }
} as FilterTypes<{ text?: []; options?: [] }>;

const StyledInput = styled.input`
  border: 0;
  color: inherit;
  :focus {
    outline: 0;
    border: 1px solid #c1cbd9;
    border-radius: 4px;
    color: #01112a;
  }
`;

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  editable
}) => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onBlur = () => {
    updateData(index, id, value);
  };
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  return (
    <StyledInput
      data-testid={`${index}-${initialValue}`}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export const defaultColumn = {
  Filter: DefaultColumnFilter,
  Cell: EditableCell,
  minWidth: 10,
  width: 150,
  maxWidth: 300
};

const range = (len) => Array.from({ length: len }, (i) => i);

const firstNames = ['Abigail', 'Alexandra', 'Alison', 'Amanda', 'Angela', 'Bella'];
const lastNames = ['Smith', 'Johnson', 'Williams', 'Jones', 'Brown', 'Davis'];
const statuses = ['single', 'relationship', 'complicated', 'married'];

const getRandomFirstName = () => firstNames[Math.floor(Math.random() * firstNames.length)]; // NOSONAR
const getRandomLastName = () => lastNames[Math.floor(Math.random() * lastNames.length)]; // NOSONAR
const getRandomStatus = () => statuses[Math.floor(Math.random() * statuses.length)]; // NOSONAR

const newPerson = () => {
  return {
    firstName: getRandomFirstName(),
    lastName: getRandomLastName(),
    age: Math.floor(Math.random() * 30), // NOSONAR
    visits: Math.floor(Math.random() * 100), // NOSONAR
    progress: Math.floor(Math.random() * 100), // NOSONAR
    status: getRandomStatus()
  };
};

export function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];

    return range(len).map(() => {
      return {
        ...newPerson(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined
      };
    });
  };

  return makeDataLevel();
}

export const headerProps = (props, column) => getStyles(props, column.align);
export const cellProps = (props, cell) => getStyles(props, cell.column.align);
const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      display: 'flex',
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'center'
    }
  }
];

// plain table options
export const columnsData = [
  {
    header: 'First Name',
    accessor: 'firstName',
    disableFilters: true,
    editable: false
  },
  {
    header: 'Last Name',
    accessor: 'lastName',
    disableFilters: true,
    editable: false
  },
  {
    header: 'Age',
    accessor: 'age',
    disableFilters: true,
    editable: false
  },
  {
    header: 'Visits',
    accessor: 'visits',
    disableFilters: true,
    editable: false
  },
  {
    header: 'Status',
    accessor: 'status',
    disableFilters: true,
    editable: false
  },
  {
    header: 'Profile Progress',
    accessor: 'progress',
    disableFilters: true,
    editable: false
  }
];

export const columnsEditable = columnsData.map((option) => ({
  ...option,
  editable: true
}));

export const defaultSortBy = [
  {
    id: 'name',
    desc: false
  }
];

export const getCellTestId = (cell: TableCellProps, cellTestIdPrefix?: string) => {
  const {
    row: { original },
    column
  } = cell;

  try {
    const canCreateTestId = !!(
      original.id &&
      ((column.header && column.header.toLowerCase) || original.testId) &&
      cellTestIdPrefix
    );

    if (canCreateTestId) {
      return `${cellTestIdPrefix}-${
        column.testId || column.header.toLowerCase().replace(/\s/gi, '-')
      }-${original.id}`;
    }

    return undefined;
  } catch {
    return undefined;
  }
};
