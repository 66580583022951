import React from 'react';
import { FilterTableIcon } from '@redislabsdev/redis-ui-icons';
import * as S from './SvgFilterIcon.style';

interface SvgFilterIconProps {
  isFilterActive: boolean;
}

const SvgFilterIcon: React.FC<SvgFilterIconProps> = ({ isFilterActive }) => {
  return (
    <S.IconButton size="M" color="primary600" icon={FilterTableIcon} $active={isFilterActive} />
  );
};

export default SvgFilterIcon;
