import { BillingAndPaymentsTheme } from 'themes/types/themeApp/components/billingAndPayments.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: BillingAndPaymentsTheme = {
  billingHistory: {
    billingHistory: {
      boldText: {
        color: themeUi.semantic.color.text.neutral800
      },
      smallText: {
        color: themeUi.semantic.color.text.neutral800
      },
      smallBlueText: {
        color: themeUi.semantic.color.text.neutral600
      },
      email: {
        color: themeUi.semantic.color.text.neutral600,
        hoverColor: themeUi.semantic.color.text.neutral600
      }
    },

    components: {
      amountCell: {
        components: {
          downLoadPdfButton: {
            downloadLink: {
              backgroundColor: themeUi.semantic.color.background.primary100,
              hoverBackgroundColor: themeUi.semantic.color.background.primary200
            }
          },

          payNowButton: {
            components: {
              statusBadge: {
                tooltipContainerWrapper: {
                  backgroundColor: themeUi.semantic.color.background.neutral800,
                  color: themeUi.semantic.color.text.neutral800
                }
              }
            }
          }
        }
      }
    }
  },

  credits: {
    flexContainer: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    },
    couponCellWrapper: {
      dangerColor: themeUi.semantic.color.text.danger500,
      normalColor: themeUi.semantic.color.text.neutral500
    },
    couponAlreadyUsed: {
      color: themeUi.semantic.color.text.danger500
    },
    blueSpan: {
      color: themeUi.semantic.color.text.neutral700
    },
    blackSpan: {
      color: themeUi.semantic.color.text.neutral800
    }
  },

  payments: {
    components: {
      editCCDetailsCard: {
        modals: {
          subscriptionSelection: {
            subscriptionTable: {
              border: themeUi.semantic.color.border.neutral400
            }
          }
        }
      },
      editMPDetailsCard: {
        bodyColor: themeUi.semantic.color.text.neutral800,
        footerColor: themeUi.semantic.color.text.neutral800,
        drawers: {
          mpAccountMappingDrawer: {
            accountMarketplaceCheckboxList: {
              label: {
                color: themeUi.semantic.color.text.neutral700
              }
            }
          }
        }
      }
    }
  }
};

export default theme;
