import { AccessManagementTheme } from 'themes/types/themeApp/components/accessManagement.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: AccessManagementTheme = {
  apiKeys: {
    components: {
      apiKeysTable: {
        components: {
          columns: {
            actions: {
              components: {
                deleteDialog: {
                  text: {
                    color: themeUi.semantic.color.text.danger500
                  }
                },

                secretCloudApiDialog: {
                  dialogFooter: {
                    color: themeUi.semantic.color.text.neutral100,
                    backgroundColor: themeUi.semantic.color.background.notice500
                  }
                }
              }
            },

            allowList: {
              allowList: {
                manageItem: {
                  color: themeUi.semantic.color.text.primary600
                }
              },

              components: {
                manageCIDR: {
                  keyLabel: {
                    color: themeUi.semantic.color.text.neutral800
                  },
                  listTitle: {
                    color: themeUi.semantic.color.text.neutral800
                  }
                }
              }
            }
          }
        }
      },

      accountKey: {
        label: {
          color: themeUi.semantic.color.text.neutral800
        }
      }
    }
  },

  singleSignOn: {
    singleSignOn: {
      TitleContainer: {
        borderBottom: themeUi.semantic.color.border.neutral400
      },
      FooterContainer: {
        borderTop: themeUi.semantic.color.border.neutral400
      },
      TitleLeftItems: {
        fill: themeUi.semantic.color.icon.neutral500
      },
      Title: {
        color: themeUi.semantic.color.text.neutral800
      },
      TokenNotification: {
        backgroundColor: themeUi.semantic.color.background.attention100
      },
      NotificationText: {
        color: themeUi.semantic.color.text.attention700
      },
      NoConfItems: {
        color: themeUi.semantic.color.text.primary600
      },
      MiddleSection: {
        color: themeUi.semantic.color.text.neutral800,
        backgroundColor: themeUi.semantic.color.background.neutral500
      },
      Indication: {
        color: themeUi.semantic.color.text.neutral700
      }
    },

    accountsSelectionSection: {
      accountName: {
        color: themeUi.semantic.color.text.neutral700,
        fontSize: themeUi.core.font.fontSize.s14
      },
      accountId: {
        color: themeUi.semantic.color.text.neutral700,
        fontSize: themeUi.core.font.fontSize.s10
      },
      accountsTitle: {
        color: themeUi.semantic.color.text.neutral700
      },
      unlinkedIndication: {
        color: themeUi.semantic.color.text.neutral700
      },
      usersCount: {
        color: themeUi.semantic.color.text.neutral700
      },
      accountsListContainer: {
        border: themeUi.semantic.color.text.neutral700,
        backgroundColor: themeUi.semantic.color.text.neutral700
      }
    },

    generalConfirmModal: {
      modalContentWidth: {
        color: themeUi.semantic.color.text.neutral800
      }
    },

    singleSignOnForm: {
      singleSignOnForm: {
        localH4: {
          color: themeUi.semantic.color.text.neutral800
        },
        editModeLabel: {
          color: themeUi.semantic.color.text.neutral800,
          colorChild: themeUi.semantic.color.text.neutral800
        },
        separator: {
          backgroundColor: themeUi.semantic.color.background.neutral400
        },
        disabledTopButtonSection: {
          color: themeUi.semantic.color.text.neutral500,
          colorChild: themeUi.semantic.color.text.neutral500
        },
        domainBindingsWrapper: {
          borderBottom: themeUi.semantic.color.border.neutral400
        },
        domainItem: {
          backgroundColor: themeUi.semantic.color.background.neutral300
        }
      },

      domainsDrawer: {
        domainOwnershipVerificationWrapper: {
          borderBottom: themeUi.semantic.color.border.neutral400
        },
        styledTable: {
          border: themeUi.semantic.color.border.neutral400
        },
        highlightedPlaceholder: {
          color: themeUi.semantic.color.text.notice500
        },
        templateBlockContainer: {
          backgroundColor: themeUi.semantic.color.text.neutral200,
          border: themeUi.semantic.color.text.neutral400
        }
      }
    },

    stepComponent: {
      stepContainer: {
        backgroundColor: themeUi.semantic.color.background.neutral300
      },
      selectedListItem: {
        backgroundColor: themeUi.semantic.color.background.primary400,
        color: themeUi.semantic.color.text.neutral100,
        backgroundColorChild: themeUi.semantic.color.background.primary600
      },
      listItem: {
        color: themeUi.semantic.color.text.primary400
      },
      stepNumber: {
        backgroundColor: themeUi.semantic.color.background.primary100
      }
    },

    tokenModal: {
      modalBodyText: {
        color: themeUi.semantic.color.text.neutral700
      },
      notificationContainer: {
        backgroundColor: themeUi.semantic.color.background.notice100,
        border: themeUi.semantic.color.border.notice300
      },
      notificationText: {
        color: themeUi.semantic.color.text.notice700
      }
    }
  },

  teams: {
    components: {
      addUser: {
        components: {
          apiKeyField: {
            apiKeyLabel: {
              color: themeUi.semantic.color.text.neutral600
            }
          },

          optionsSection: {
            header: {
              color: themeUi.semantic.color.text.neutral700
            }
          },

          toggleField: {
            toggleLabel: {
              color: themeUi.semantic.color.text.neutral800
            }
          }
        }
      }
    }
  }
};

export default theme;
