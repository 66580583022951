import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';

export const LoginFromInvitationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 7.2rem 0;
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignInTitle = styled(Typography.Heading)`
  color: ${({ theme }) =>
    theme.appComponents.login.components.signInFromUserInvitation.signInTitle.color};
`;

export const SetPasswordFormTitle = styled(Typography.Body)`
  color: ${({ theme }) =>
    theme.appComponents.login.components.signInFromUserInvitation.setPasswordFormTitle.color};
  margin-bottom: 2.4rem;
  font-size: 1.8rem;
`;
