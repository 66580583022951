import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) =>
    theme.appComponents.login.components.mobileBanner.bannerContainer.backgroundColor};
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.6rem;
  height: 7.2rem;
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem;
`;

export const Heading = styled(Typography.Heading)`
  color: ${({ theme }) => theme.appComponents.login.components.mobileBanner.heading.color};
  margin-bottom: 0.8rem;
`;
export const Body = styled(Typography.Body)`
  color: ${({ theme }) => theme.appComponents.login.components.mobileBanner.body.color};
  margin-bottom: 3.2rem;
`;
