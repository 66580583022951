/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import React from 'react';
import { Checkbox } from '@redislabsdev/redis-ui-components';
import { IOption } from '../ColumnOptionsFilter';

import * as S from './OptionCheckboxes.style';

interface IProps {
  options: IOption;
  onChange: (e) => void;
}

const OptionCheckboxes: React.FC<IProps> = ({ options, onChange }) => {
  const handleCheckboxChange = (name) => {
    return (checked) => {
      onChange({
        target: {
          name,
          checked
        }
      });
    };
  };

  return (
    <S.OptionsContainer>
      {Object.keys(options).map((optionKey, i) => {
        const { label, checked } = options[optionKey];

        return (
          <Checkbox
            key={`${label}${i}`}
            id={`${optionKey}`}
            label={label}
            checked={checked}
            onCheckedChange={handleCheckboxChange(optionKey)}
          />
        );
      })}
    </S.OptionsContainer>
  );
};

export default OptionCheckboxes;
