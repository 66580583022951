/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPlainObject } from '../../types/interfaces';
import { Account } from '../account/account.types';

export enum PaymentMethodsTypesEnum {
  GET_PAYMENTS_INFO_REQUEST = 'GET_PAYMENTS_INFO_REQUEST',
  GET_PAYMENTS_INFO_SUCCESS = 'GET_PAYMENTS_INFO_SUCCESS',
  GET_PAYMENTS_INFO_FAILURE = 'GET_PAYMENTS_INFO_FAILURE',
  DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST',
  DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS',
  DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE',
  ADD_SUBS_TO_PAYMENT_METHOD_REQUEST = 'ADD_SUBS_TO_PAYMENT_METHOD_REQUEST',
  ADD_SUBS_TO_PAYMENT_METHOD_SUCCESS = 'ADD_SUBS_TO_PAYMENT_METHOD_SUCCESS',
  ADD_SUBS_TO_PAYMENT_METHOD_FAILURE = 'ADD_SUBS_TO_PAYMENT_METHOD_FAILURE'
}

export type PaymentsActionTypes =
  | GetPaymentsInfoAction
  | GetPaymentsInfoSuccessAction
  | GetPaymentsInfoFailureAction
  | DeletePaymentMethodSuccessAction
  | AddSubsToPaymentMethodAction
  | AddSubsToPaymentMethodSuccessAction
  | AddSubsToPaymentMethodFailureAction;

export interface GetPaymentsInfoAction {
  type: PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_REQUEST;
}

export interface GetPaymentsInfoSuccessAction {
  type: typeof PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_SUCCESS;
  payload: IPaymentInfo[];
}

export interface GetPaymentsInfoFailureAction {
  type: typeof PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_FAILURE;
  error: string;
}

export interface IPaymentInfoState extends BaseState {
  data: IPaymentInfo[];
}

export interface DeletePaymentMethodAction {
  type: PaymentMethodsTypesEnum.DELETE_PAYMENT_METHOD_REQUEST;
  payload: number;
}

export interface DeletePaymentMethodSuccessAction {
  type: typeof PaymentMethodsTypesEnum.DELETE_PAYMENT_METHOD_SUCCESS;
  payload: number;
}

export interface DeletePaymentMethodFailureAction {
  type: typeof PaymentMethodsTypesEnum.DELETE_PAYMENT_METHOD_FAILURE;
  error: string;
}

export interface AddSubsToPaymentMethodAction {
  type: PaymentMethodsTypesEnum.ADD_SUBS_TO_PAYMENT_METHOD_REQUEST;
  payload: SubscriptionsPaymentData;
}

export interface AddSubsToPaymentMethodSuccessAction {
  type: PaymentMethodsTypesEnum.ADD_SUBS_TO_PAYMENT_METHOD_SUCCESS;
  payload: SubscriptionsPaymentData;
}

export interface AddSubsToPaymentMethodFailureAction {
  type: typeof PaymentMethodsTypesEnum.ADD_SUBS_TO_PAYMENT_METHOD_FAILURE;
  error: string;
}

export interface SubscriptionsPaymentData {
  pmID: number;
  subIDs: string[];
  onSuccess?: () => void;
  onError?: () => void;
}

export type CCCode =
  | 'VISA'
  | 'JCB'
  | 'MASTERCARD'
  | 'AMEX'
  | 'DINERS'
  | 'CARTE_BLEUE'
  | 'DISCOVER'
  | 'MAESTR_UK'
  | 'SOLO'
  | 'UNIONPAY'
  | 'AWS_MARKETPLACE'
  | 'GCP_MARKETPLACE'
  | 'AZURE_MARKETPLACE'
  | 'AMERICAN EXPRESS'
  | 'CREDIT_CARD_PLACEHOLDER';

export type PaymentInfoMethods = 'credit-card' | 'wire-transfer' | 'marketplace';

export interface IPaymentInfo {
  id: number;
  order_id?: string;
  account_id?: string;
  credit_card_code: CCCode;
  credit_card_name: string;
  method: PaymentInfoMethods;
  cc_last_4_digits: string;
  encrypted_card_number?: any;
  encrypted_security_code?: any;
  vat_id?: any;
  company_tax_id?: any;
  expiration_month: number;
  expiration_year: number;
  company: string;
  first_name: string;
  last_name: string;
  email?: any;
  account: Account;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  state?: any;
  state_name?: any;
  country: string;
  country_name: string;
  cc_address1: string;
  cc_address2: string;
  cc_city: string;
  cc_zip: string;
  cc_state?: any;
  cc_state_name?: any;
  cc_country: string;
  cc_country_name: string;
  payment_provider_name?: string;
  payment_provider_customer_id?: string;
  errors?: IPlainObject[];
}
