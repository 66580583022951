import { LoginMigrationsTheme } from 'themes/types/themeApp/components/loginMigrations.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: LoginMigrationsTheme = {
  redisBackground: {
    color: themeUi.semantic.color.text.neutral100
  }
};

export default theme;
