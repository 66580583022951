export const getDataState = (disabled = false, selected = false) => {
  let status = 'unselected';
  if (disabled) {
    status = 'disabled';
  }
  if (selected) {
    status = 'selected';
  }

  return { 'data-state': status };
};

export type TestIdPrefixes =
  | 'selection-area'
  | 'listbox'
  | 'table'
  | 'row'
  | 'selectbox'
  | 'radiobutton'
  | 'container'
  | 'section'
  | 'readonlyvalue'
  | 'readonlylabel'
  | 'link'
  | 'button'
  | 'cc-type'
  | 'checkbox'
  | 'collapse'
  | 'summary'
  | 'list'
  | 'switch'
  | 'banner'
  | 'tablerow';

export const getDataTestId = (dataTestIdPrefix: TestIdPrefixes, dataTestIdSuffix: string) =>
  dataTestIdSuffix && { 'data-testid': `${dataTestIdPrefix}--${dataTestIdSuffix}` };

export const stringToTestId = (str: string) => {
  if (str) {
    return str.trim().split(' ').join('-');
  }

  return '';
};

export const stringToReadOnlyTestId = (value: string, type: 'label' | 'value'): string => {
  if (value) {
    const suffix = stringToTestId(value);
    if (type === 'label') {
      return `readonlylabel--${suffix}`;
    }

    return `readonlyvalue--${suffix}`;
  }

  return '';
};
