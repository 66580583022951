import styled from 'styled-components/macro';
import { Select } from '@redislabsdev/redis-ui-components';
import { transformToRem } from '../../utils/dimensions';

export { Tr, Td } from '../../Table.style';

export const PAGINATION_SIZE = 40;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${transformToRem(PAGINATION_SIZE)}rem;
  color: ${({ theme }) =>
    theme.appComponents.shared.table.components.tablePagination.paginationContainer.color};
  border-top-width: 0.1rem;
  padding: 1.1rem 0.75rem 0.9rem 1.6rem;
  cursor: auto;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const TextContainer = styled.span<{ disabled?: boolean }>`
  margin: 0 4px;
  color: ${({ disabled, theme }) =>
    disabled
      ? theme.appComponents.shared.table.components.tablePagination.textContainer.disabledColor
      : theme.appComponents.shared.table.components.tablePagination.textContainer.enabledColor};
  font-size: 1.2rem;
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  & button {
    height: 2rem;
    padding: 0 0.4rem;
    min-width: unset;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DropdownText = styled.span<{ disabled?: boolean; marginLeft?: number }>`
  margin-right: 0.5rem;
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}rem` : 0)};
  font-size: 1.2rem;
  color: ${({ disabled, theme }) =>
    disabled
      ? theme.appComponents.shared.table.components.tablePagination.dropdownText.disabledColor
      : theme.appComponents.shared.table.components.tablePagination.dropdownText.enabledColor};
`;

export const PageCountDropdownMenu = styled(Select)`
  font-size: 1.2rem;
  min-width: fit-content;
  width: auto;
`;

export const PageDropdownMenu = styled(Select)`
  font-size: 1.2rem;
  min-width: 4.5rem;
  width: auto;
`;

export const MarginHorizontalWrapper = styled.div`
  margin-left: 4rem;
`;
