import { CloudProviders } from '../../components/CloudProvider/CloudProvider.types';

export interface FindRegionMinimalPriceProps {
  minimalPricingList: SubsMinimalPricing[];
  provider: CloudProviders;
  rof: boolean;
  regionName: string;
}

const findRegionMinimalPrice = ({
  minimalPricingList,
  provider,
  rof,
  regionName
}: FindRegionMinimalPriceProps) => {
  const priceItem = minimalPricingList.find((minimalPriceItem) => {
    if (minimalPriceItem.cloud_name.toLocaleLowerCase() !== provider) {
      return false;
    }
    if (minimalPriceItem.region_name !== regionName) {
      return false;
    }
    if (minimalPriceItem.rof !== rof) {
      return false;
    }

    return true;
  });

  return priceItem?.price || 0;
};

export default findRegionMinimalPrice;
