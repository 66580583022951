const paymentInfosKeys = {
  getAllCountries: ['all-countries'],
  validateVatId: ['validateVatId'],
  getThreeDsToken: ['threeDsToken'],
  getPaymentInfo: (paymentInfoId: number) => ['paymentInfos', paymentInfoId],
  getAllowedMarketplaces: ['allowed-marketplaces'],
  getCurrentMarketplace: ['current-marketplace'],
  updateSubscriptionPaymentInfoToMarketplace: ['update-subs-payment-info-to-marketplace'],
  deleteMarketplaceForCurrentAccount: ['delete-marketplace-for-current-account'],
  addMarketplaceForAccount: ['add-marketplace-for-account']
};

export default paymentInfosKeys;
