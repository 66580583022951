import { useEffect, useState } from 'react';
import { ThemeMain } from 'themes/types/theme.types';
import { useFeatureFlags } from 'components/FeatureFlagsProvider/hooks/useFeatureFlags';
import { theme as themeOld } from '../themeOld/theme';
import { theme as themeLight } from '../themeLight/theme';
import { theme as themeDark } from '../themeDark/theme';
import { getStoredThemeName, storeThemeName } from './utils/themeStorage/themeStorage';
import { applyTheme } from './utils/applyTheme/applyTheme';
import { ThemeName } from './useTheme.types';

export const themes: { [K in ThemeName]: ThemeMain } = {
  old: themeOld,
  light: themeLight,
  dark: themeDark
};

export const initialThemeName: ThemeName = 'old';
export const defaultTheme = themes[initialThemeName];

const useTheme = () => {
  const {
    flags: { enableRebranding },
    isLoading
  } = useFeatureFlags();
  const [name, setName] = useState<ThemeName>(initialThemeName);

  useEffect(() => {
    const storedSelectedThemeName = getStoredThemeName();
    setThemeName(storedSelectedThemeName);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const storedSelectedThemeName = getStoredThemeName();

      if (enableRebranding) {
        setThemeName(storedSelectedThemeName === 'old' ? 'light' : storedSelectedThemeName);
      } else {
        setThemeName('old');
      }
    }
  }, [enableRebranding, isLoading]);

  const setThemeName = (themeName: ThemeName) => {
    applyTheme(themeName);
    storeThemeName(themeName);
    setName(themeName);
  };

  return {
    themeName: name,
    theme: themes[name],
    setThemeName
  };
};

export default useTheme;
