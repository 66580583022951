import { LoginTheme } from 'themes/types/themeApp/components/login.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: LoginTheme = {
  login: {
    formContainer: {
      backgroundColor: themeUi.semantic.color.text.neutral100,
      boxShadow: 'none',
      border: `1px solid ${themeUi.semantic.color.text.neutral600}`,
      color: themeUi.semantic.color.text.neutral700,
      linkColor: themeUi.semantic.color.text.primary400
    },

    background: {
      backgroundColor: themeUi.color.dark900
    }
  },

  components: {
    forgotPasswordForm: {
      forgotPasswordTitle: {
        color: themeUi.semantic.color.text.neutral800
      },
      forgotPasswordDescription: {
        color: themeUi.semantic.color.text.neutral700
      }
    },

    mobileBanner: {
      bannerContainer: {
        backgroundColor: themeUi.semantic.color.background.neutral100
      },
      heading: {
        color: themeUi.semantic.color.text.neutral800
      },
      body: {
        color: themeUi.semantic.color.text.neutral700
      }
    },

    orSeperator: {
      orText: {
        color: themeUi.semantic.color.text.neutral800,
        backgroundColor: themeUi.semantic.color.background.neutral400
      }
    },

    resetPassword: {
      resetPasswordFormTitle: {
        color: themeUi.semantic.color.text.neutral800
      }
    },

    signInForm: {
      signInTitle: {
        color: themeUi.semantic.color.text.neutral800
      }
    },

    signInFromUserInvitation: {
      signInTitle: {
        color: themeUi.semantic.color.text.neutral800
      },
      setPasswordFormTitle: {
        color: themeUi.semantic.color.text.neutral800
      }
    },

    signUp: {
      components: {
        signUpForm: {
          inlineTerms: {
            color: themeUi.semantic.color.text.neutral700,
            aColor: themeUi.semantic.color.text.primary400,
            checkboxColor: themeUi.semantic.color.text.primary400
          },
          bigTitleRules: {
            color: themeUi.semantic.color.text.neutral800
          },
          formTitle: {
            color: themeUi.semantic.color.background.neutral500
          }
        }
      }
    },

    socialLogin: {
      socialLoginButton: {
        socialLoginName: {
          color: themeUi.semantic.color.text.neutral800
        }
      }
    },

    ssoLoginForm: {
      signInTitle: {
        color: themeUi.semantic.color.text.neutral800
      }
    },

    userFormInfo: {
      userFormInfoTitle: {
        color: themeUi.semantic.color.text.neutral800
      },
      userFormInfoSubTitle: {
        color: themeUi.semantic.color.text.neutral800
      },
      userFormInfoText: {
        color: themeUi.semantic.color.text.neutral700
      }
    }
  }
};

export default theme;
