import styled from 'styled-components/macro';
import { Banner, Typography } from '@redislabsdev/redis-ui-components';

export const SsoLoginFormContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 10rem;
  width: 36rem;
`;

export const SignInTitle = styled(Typography.Heading)`
  color: ${({ theme }) => theme.appComponents.login.components.ssoLoginForm.signInTitle.color};
`;

export const ErrorContainer = styled.div`
  display: flex;
  margin-top: 1.6rem;
`;

export const ErrorBanner = styled(Banner)`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  width: 100%;
`;

export const FormButtonsContainer = styled.div`
  justify-content: end;
  display: flex;
  margin-top: 4rem;
  gap: 0.7rem;
`;
