import { ModulesTheme } from 'themes/types/themeApp/components/modules.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: ModulesTheme = {
  advancedCapabilitiesTitle: {
    recommendedText: {
      color: themeUi.semantic.color.text.neutral700
    },
    title: {
      color: themeUi.semantic.color.text.neutral800
    }
  },

  modulesCheckboxSelectList: {
    modulesCheckboxSelectList: {
      orText: {
        disabledColor: themeUi.semantic.color.text.neutral500,
        enabledColor: themeUi.semantic.color.text.primary700
      },
      orContainer: {
        beforeBackgroundColor: themeUi.semantic.color.text.neutral400,
        afterBackgroundColor: themeUi.semantic.color.text.neutral400
      }
    },

    utils: {
      getModuleIconByName: {
        tokenColor: themeUi.semantic.color.icon.neutral700,
        disabledTokenColor: themeUi.semantic.color.icon.neutral500
      }
    },

    components: {
      moduleCheckbox: {
        checkboxInput: {
          borderColor: themeUi.semantic.color.border.neutral400
        },

        checkboxContainer: {
          border: themeUi.semantic.color.border.neutral400,

          checkedBorder: themeUi.semantic.color.border.primary600,
          checkedBorderBottom: themeUi.semantic.color.border.primary600,
          checkedBorderColor: themeUi.semantic.color.border.primary600,

          notCheckedBorder: themeUi.semantic.color.border.notice500,
          notCheckedBorderBottom: themeUi.semantic.color.border.notice500,
          notCheckedBorderColor: themeUi.semantic.color.border.notice500
        },

        ModuleName: {
          color: themeUi.semantic.color.text.neutral500
        }
      },

      searchModulePopover: {
        popoverText: {
          color: themeUi.semantic.color.text.neutral800
        },
        moduleName: {
          color: themeUi.semantic.color.text.primary600
        }
      }
    }
  }
};

export default theme;
