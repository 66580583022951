import { SetupCompletenessTheme } from 'themes/types/themeApp/components/setupCompleteness.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: SetupCompletenessTheme = {
  setupCompletenessBar: {
    bannerCompose: {
      borderBottom: themeUi.semantic.color.border.neutral400,
      backgroundColor: themeUi.semantic.color.background.primary50
    },
    bannerMessage: {
      color: themeUi.semantic.color.text.primary700
    }
  },

  setupCompletenessButton: {
    badge: {
      border: themeUi.semantic.color.text.neutral100,
      backgroundColor: themeUi.semantic.color.text.success500
    }
  },

  setupCompletenessDrawer: {
    setupCompletenessDrawer: {
      title: {
        color: themeUi.semantic.color.text.neutral800
      },
      caption: {
        color: themeUi.semantic.color.text.neutral800
      },
      containerFooter: {
        backgroundColor: themeUi.semantic.color.background.primary50
      },
      footerText: {
        color: themeUi.semantic.color.text.neutral700
      },
      footerLink: {
        color: themeUi.semantic.color.text.primary600
      },
      line: {
        backgroundColor: themeUi.semantic.color.background.neutral400
      },
      collapseContainer: {
        backgroundColor: themeUi.semantic.color.background.neutral200,
        color: themeUi.semantic.color.text.neutral800,
        fill: themeUi.semantic.color.icon.neutral600,
        fontFamily: themeUi.core.font.fontFamily.regular
      },
      collapseTitleCompleted: {
        color: themeUi.semantic.color.text.success600,
        backgroundColor: themeUi.semantic.color.background.success100,
        border: themeUi.semantic.color.border.success100
      },
      collapseTitleCompletedIcon: {
        fill: themeUi.semantic.color.icon.success600
      }
    },

    components: {
      setupCompletenessDrawerLink: {
        container: {
          backgroundColor: themeUi.semantic.color.background.neutral100,
          border: themeUi.semantic.color.border.neutral400
        },
        title: {
          color: themeUi.semantic.color.text.primary600
        },
        description: {
          color: themeUi.semantic.color.text.neutral700
        }
      },

      setupCompletenessDrawerTask: {
        container: {
          color: themeUi.semantic.color.text.neutral700
        },
        icon: {
          checkedFill: themeUi.semantic.color.icon.success500,
          notCheckedfill: themeUi.semantic.color.icon.neutral400
        },
        description: {
          color: themeUi.semantic.color.text.neutral700
        }
      }
    }
  }
};

export default theme;
