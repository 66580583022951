import { IconButton as IconButtonComponent } from '@redislabsdev/redis-ui-components';
import styled from 'styled-components/macro';

export const IconButton = styled(IconButtonComponent)<{ $active: boolean }>`
  ${({ $active, theme }) =>
    $active &&
    `
      background: ${theme.appComponents.shared.table.components.svgFilterIcon.iconButton.backgroundColor};
      &:hover {
        background: ${theme.appComponents.shared.table.components.svgFilterIcon.iconButton.hoverBackgroundColor};
      }
    `}
`;
