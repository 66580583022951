import { MaintenanceBannerTheme } from 'themes/types/themeApp/components/maintenanceBanner.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: MaintenanceBannerTheme = {
  banner: {
    color: themeUi.semantic.color.text.notice500
  },
  inMaintenanceIcon: {
    color: themeUi.semantic.color.text.notice500,
    fill: themeUi.semantic.color.icon.notice500,
    pathFill: themeUi.semantic.color.icon.notice500
  },
  scheduledMaintenanceIcon: {
    color: themeUi.semantic.color.text.notice500,
    fill: themeUi.semantic.color.icon.notice500,
    pathFill: themeUi.semantic.color.icon.notice500
  },
  failoverEventIcon: {
    color: themeUi.semantic.color.text.notice500,
    fill: themeUi.semantic.color.icon.notice500,
    pathFill: themeUi.semantic.color.icon.notice500
  }
};

export default theme;
