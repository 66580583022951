import { AccountSettingsTheme } from 'themes/types/themeApp/components/accountSettings.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: AccountSettingsTheme = {
  account: {
    shared: {
      card: {
        card: {
          backgroundColor: themeUi.semantic.color.background.neutral100,
          boxShadow: themeUi.core.shadow.shadow4
        },

        cardHeader: {
          color: themeUi.semantic.color.text.neutral800,
          borderBottom: themeUi.semantic.color.border.neutral400
        }
      },

      divider: {
        divider: {
          darkBorderBottom: themeUi.semantic.color.border.neutral500,
          borderBottom: themeUi.semantic.color.border.neutral400
        }
      },

      sectionItem: {
        label: {
          color: themeUi.semantic.color.text.neutral800
        }
      }
    }
  },

  cloudAccounts: {
    addEditModal: {
      form: {
        cloudAccAddEditForm: {
          leftBox: {
            borderRight: themeUi.semantic.color.border.neutral400
          },
          boxTitle: {
            color: themeUi.semantic.color.text.neutral800
          }
        }
      }
    },

    cloudAccountsEmptyResults: {
      readMoreBtn: {
        color: themeUi.semantic.color.text.primary600
      }
    },

    components: {
      tableCells: {
        cells: {
          svgIcon: {
            color: themeUi.semantic.color.icon.primary600
          }
        }
      }
    }
  }
};

export default theme;
