import { Form } from 'formik';
import styled, { css } from 'styled-components/macro';
import { TableStyles } from 'components/Shared/Table';
import { MAIN_PAGE_PADDING } from './constants.style';
import { CollisionBoundary } from './CollisionBoundary';

export const FlexCentered = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface FullWidth {
  $fullWidth?: boolean;
  $leftSpace?: boolean;
}

interface ScrollContainerProps extends FullWidth {
  subtractFromHeight?: number;
  fullWidth?: boolean;
  fullHeight?: boolean;
  scrollBarPadding?: boolean;
  leftSpace?: boolean;
}

const FullWidthWithPadding = css`
  width: calc(100% + ${MAIN_PAGE_PADDING});
`;

const FullWidthWithPaddingAndSpace = css`
  width: calc(100% + ${MAIN_PAGE_PADDING} + 2rem);
`;

export const ScrollContainer = styled(CollisionBoundary)<ScrollContainerProps>(
  ({ fullWidth, scrollBarPadding, subtractFromHeight = 2, leftSpace, fullHeight }) => css`
    max-height: calc(100% - ${subtractFromHeight}rem);
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;

    ${fullWidth && FullWidthWithPadding}
    ${scrollBarPadding &&
    css`
      padding: ${({ theme }) => theme.appComponents.scrollContainer.padding};
    `}
    ${leftSpace &&
    css`
      padding-left: 2rem;
    `}
    ${fullHeight &&
    css`
      height: 100%;
    `}
  `
);

export const FullHeightForm = styled(Form)<FullWidth>`
  height: 100%;
  ${({ $fullWidth }) => $fullWidth && FullWidthWithPadding}
  ${({ $leftSpace }) =>
    $leftSpace &&
    css`
      margin-left: -2rem;
      ${FullWidthWithPaddingAndSpace}
    `}
  overflow: hidden;
  position: relative;
`;

export const smallTableStyle = css`
  ${TableStyles.Tbody} {
    max-height: 55rem;
  }
`;
