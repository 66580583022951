import axios from 'axios';
import { AccessToken } from '@okta/okta-auth-js';
/* eslint-disable no-param-reassign */
import { sessionExpired } from 'store/auth/auth.action';
import { queryClient } from 'utils/initializeQueryClient';
import {
  genericErrorStatus,
  serviceUnavailableStatus,
  unauthorizedStatus
} from '../../../utils/constants/api/statuses';
import { extractCbError } from '../../../utils/helpers/extractCbError';
import logHttpResponseError from '../../sentry/utils/logHttpResponseError';
import { ExtendedAxiosError } from '../../../types/interfaces';
import history from '../../../hashHistory';
import { routes } from '../../../utils/constants/routes';
import { extractErrorAndShowToast } from '../../../utils/helpers/extractErrorAndShowToast';
import { SmOktaAuth } from '../../../utils/oktaInstance';
import { USER_MFA_REQUIRED } from '../../../utils/constants/api/codes';
import store from '../../../store';
import shouldTriggerCaptcha from './utils/shouldTriggerCaptcha';
import captchaHandler from './utils/captchaHandler';

const setRequestInterceptor = (accessToken: AccessToken | null) => (requestConfig) => {
  return {
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      Authorization: accessToken ? `Bearer ${accessToken.accessToken}` : null
    }
  };
};

const interceptorId = { id: null };

export const setOktaAuthorizationHeader = (accessToken: AccessToken) => {
  if (interceptorId.id !== null) {
    RedisApiAxiosInstance.interceptors.request.eject(interceptorId.id);
  }

  interceptorId.id = RedisApiAxiosInstance.interceptors.request.use(
    setRequestInterceptor(accessToken)
  );
};

const redirectOnGenericErrorList = ['users', 'accounts'];
const shouldRedirectOnGenericError = (url = '', whitelist = []) => {
  let shouldRedirect = false;
  for (let index = 0; index < whitelist.length; index++) {
    const urlSubStr = whitelist[index];
    if (url.includes(urlSubStr)) {
      shouldRedirect = true;
      break;
    }
  }

  return shouldRedirect;
};

const RedisApiAxiosInstance = axios.create({
  withCredentials: true
});

export const setCSRFHeader = (token) => {
  RedisApiAxiosInstance.defaults.headers.common['X-CSRF-Token'] = token;
};

RedisApiAxiosInstance.interceptors.response.use(
  async (response) => {
    if (shouldTriggerCaptcha(response)) {
      await captchaHandler();

      return RedisApiAxiosInstance(response.config);
    }

    return response;
  },
  async (error: ExtendedAxiosError) => {
    if (error?.response) {
      const {
        status,
        data,
        config: { url }
      } = error.response;
      error.response.errorInfo = extractCbError(data);
      logHttpResponseError(error);
      const isGenericErrWithPermission =
        status === genericErrorStatus &&
        shouldRedirectOnGenericError(url, redirectOnGenericErrorList);

      if (status === unauthorizedStatus || isGenericErrWithPermission) {
        if (isGenericErrWithPermission) {
          extractErrorAndShowToast(error);
        }

        if (data?.errors?.code === USER_MFA_REQUIRED) {
          return Promise.reject(error);
        }

        const msAuthError = [
          'kyc',
          'maintenance-window',
          'cloud-transit-gateway',
          'rdi',
          'tagging',
          'cloud-db-service'
        ].reduce((acc, curr) => {
          if (url.includes(curr)) {
            return true;
          }

          return acc;
        }, false);

        if (msAuthError) {
          return Promise.reject(error);
        }

        const { search, pathname } = history.location;
        const comingFromLogout = window.sessionStorage.getItem('logOutFlag'); // used to mark if the requests is coming after a logout action
        const isLoginPath = pathname.includes(routes.login);

        if (!isLoginPath && !comingFromLogout) {
          window.sessionStorage.setItem('locationPathName', pathname);
          if (window.location.pathname.length > 1) {
            window.sessionStorage.setItem('locationEnvPath', window.location.pathname);
          }
        }
        search && window.sessionStorage.setItem('queryParams', search);
        window.sessionStorage.removeItem('logOutFlag'); // used to mark if the requests is coming after a logout action

        try {
          const oktaAuth = SmOktaAuth.getInstance();
          setOktaAuthorizationHeader(null);
          oktaAuth?.tokenManager.clear();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('err: ', { ...err });
        } finally {
          store.dispatch(sessionExpired());
        }

        return Promise.reject(error);
      }
      if (status === serviceUnavailableStatus && history.location.pathname !== routes.maintenance) {
        queryClient.removeQueries();
        history.push(routes.maintenance);
      }
    }

    return Promise.reject(error);
  }
);

export default RedisApiAxiosInstance;
