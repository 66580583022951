import { ThemeApp } from 'themes/types/themeApp/theme.types';
import subscriptionSideBar from './components/subscriptionSideBar';
import sectionCard from './components/sectionCard';
import scrollContainer from './components/scrollContainer';
import totalPriceFooter from './components/totalPriceFooter';
import radiusAndShadow from './components/radiusAndShadow';
import tabs from './components/tabs';
import main from './components/main';
import pagesTitle from './components/pagesTitle';
import userProfile from './components/userProfile';
import usageReport from './components/usageReport';
import maintenanceBanner from './components/maintenanceBanner';
import dbIndicator from './components/dbIndicator';
import viewDb from './components/viewDb';
import viewCrdb from './components/viewCrdb';
import databaseTable from './components/databaseTable';
import dataAccessControl from './components/dataAccessControl';
import subscriptionsAndDatabases from './components/subscriptionsAndDatabases';
import marketplace from './components/marketplace';
import login from './components/login';
import sessionLogs from './components/sessionLogs';
import loginMigrations from './components/loginMigrations';
import downloadCenter from './components/downloadCenter';
import createSubscription from './components/createSubscription';
import billingAndPayments from './components/billingAndPayments';
import accountSettings from './components/accountSettings';
import accessManagement from './components/accessManagement';
import setupCompleteness from './components/setupCompleteness';
import modules from './components/modules';
import topBar from './components/topBar';
import shared from './components/shared';
import connectionWizard from './components/connectionWizard';
import components from './components/components';

export const themeApp: ThemeApp = {
  appComponents: {
    subscriptionSideBar,
    sectionCard,
    scrollContainer,
    totalPriceFooter,
    radiusAndShadow,
    tabs,
    main,
    pagesTitle,
    userProfile,
    usageReport,
    maintenanceBanner,
    dbIndicator,
    viewDb,
    viewCrdb,
    databaseTable,
    dataAccessControl,
    subscriptionsAndDatabases,
    marketplace,
    login,
    sessionLogs,
    loginMigrations,
    downloadCenter,
    createSubscription,
    billingAndPayments,
    accountSettings,
    accessManagement,
    setupCompleteness,
    modules,
    topBar,
    shared,
    connectionWizard,
    components
  }
};
