import styled from 'styled-components/macro';
import { Loader as LoaderComponent } from '@redislabsdev/redis-ui-components';

export const FORM_WIDTH = '68rem';
export const FORM_MARGIN_RIGHT = '3.6rem';

export const FormContainer = styled.div`
  background-color: ${({ theme }) => theme.appComponents.login.login.formContainer.backgroundColor};
  display: flex;
  justify-content: center;
  margin: 4rem ${FORM_MARGIN_RIGHT} 4rem 0;
  border-radius: 1.6rem;
  width: ${FORM_WIDTH};
  min-height: 64rem;
  min-width: 42rem;
  box-shadow: ${({ theme }) => theme.appComponents.login.login.formContainer.boxShadow};
  border: ${({ theme }) => theme.appComponents.login.login.formContainer.border};
  color: ${({ theme }) => theme.appComponents.login.login.formContainer.color};

  & a {
    color: ${({ theme }) => theme.appComponents.login.login.formContainer.linkColor};
  }
`;

export const Loader = styled(LoaderComponent)`
  height: 100vh;
`;

export const LoginLoaderWrapper = styled(Loader)`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 90%);
  display: flex;
  flex: 1;
  justify-content: center;
  position: absolute;
  z-index: 1;
  border-radius: 0.8rem;
`;
