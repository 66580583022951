import { ViewCrdbTheme } from 'themes/types/themeApp/components/viewCrdb.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: ViewCrdbTheme = {
  components: {
    regionDetails: {
      regionLocation: {
        color: themeUi.semantic.color.text.neutral800
      }
    },

    crdbConfiguration: {
      components: {
        dangerZoneSection: {
          dangerZoneCard: {
            borderBottom: themeUi.semantic.color.border.neutral400
          }
        },

        generalSection: {
          components: {
            crdbName: {
              color: themeUi.semantic.color.text.neutral800
            },
            globalDetails: {
              labelsContainer: {
                color: themeUi.semantic.color.text.neutral800
              },
              divider: {
                backgroundColor: themeUi.semantic.color.background.neutral400
              }
            }
          }
        },

        internalSettingsSection: {
          components: {
            crdbCompression: {
              Description: {
                color: themeUi.semantic.color.text.neutral600
              },
              CompressionValue: {
                color: themeUi.semantic.color.text.neutral800
              }
            }
          }
        },

        overridingRegionsInfo: {
          title: {
            color: themeUi.semantic.color.text.neutral800
          }
        },

        overrideGlobalCheckbox: {
          container: {
            color: themeUi.semantic.color.text.neutral800
          }
        },

        scalabilitySection: {
          components: {
            instanceThroughput: {
              throughputLabel: {
                color: themeUi.semantic.color.text.neutral800
              },
              components: {
                throughputItem: {
                  opsPerSecText: {
                    color: themeUi.semantic.color.text.neutral600
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export default theme;
