import { CurrentDate } from 'components/Shared/DateTimePicker/DateTimePicker.types';

export enum SessionLogsTypesEnum {
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  CLEAR_FILTERS = 'CLEAR_FILTERS'
}

export interface SessionTypeItem {
  code: string;
  label: string;
}

export type UpdateFiltersAction = {
  type: SessionLogsTypesEnum.UPDATE_FILTERS;
  payload: Record<keyof Partial<SessionLogsState>, string | number | CurrentDate | SessionTypeItem>;
};

export type ClearFiltersAction = {
  type: SessionLogsTypesEnum.CLEAR_FILTERS;
};

export type SessionLogsActionTypes = UpdateFiltersAction | ClearFiltersAction;

export interface SessionLogsState {
  search: string | null;
  date: CurrentDate;
  type: SessionTypeItem | null;
  accountId: string | null;
}
