import { css } from 'styled-components/macro';
import { MainTheme } from 'themes/types/themeApp/components/main.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: MainTheme = {
  mainContainer: {
    props: css`
      //old table
      div:has(> div[role='table']) {
        & div[role='table'] {
          background-color: ${themeUi.semantic.color.background.neutral100};

          & div[role='columnheader'] {
            color: ${themeUi.semantic.color.text.neutral800};
            background: none;

            & > div {
              border-right: solid 1px ${themeUi.semantic.color.border.neutral400};
              padding-right: 0.5rem;
              height: 4rem;
            }
          }
          & div[role='columnheader']:last-child {
            & > div {
              border-right: none;
            }
          }
          & div[role='rowgroup'] {
            color: ${themeUi.semantic.color.text.neutral800};
            border-color: ${themeUi.semantic.color.border.neutral400};

            & div[role='row'] {
              color: ${themeUi.semantic.color.text.neutral700};
              border-color: ${themeUi.semantic.color.border.neutral400};

              & div[role='cell'] > div {
                border-color: ${themeUi.semantic.color.border.neutral600};
              }
            }
          }

          & div[role='rowgroup'][data-role='table-footer'] {
            color: ${themeUi.semantic.color.text.neutral800};
            border-color: ${themeUi.semantic.color.border.primary100};

            & div[role='row'] {
              & div[role='cell'] {
                & span {
                  color: ${themeUi.semantic.color.text.neutral700};
                }

                & button:not([disabled]) {
                  color: ${themeUi.semantic.color.text.neutral700};
                }

                & button:disabled {
                  color: ${themeUi.semantic.color.text.neutral700};
                }
              }
            }
          }
        }
      }

      //plan selection
      section[class*='PlanSelectionstyle'] {
        & ul {
          margin-top: 0.5rem;
        }

        & > div {
          margin-left: 0;
          box-shadow: ${themeUi.semantic.color.border.neutral600} 0px 0px 0px 1px;
        }

        & li[data-selected='false'] {
          border-right: solid 1px ${themeUi.semantic.color.border.neutral600};
        }

        & li[data-selected='true'] {
          border: solid 1px ${themeUi.semantic.color.border.neutral600};
          border-right: none;

          & :not(:hover) {
            box-shadow: none;
          }
        }
      }

      // subscription overview
      section:has(span[data-testid='plan-card-main-container']) {
        div[data-role='listitem'] {
          box-shadow: ${themeUi.semantic.color.border.neutral600} 0px 0px 0px 1px;
        }
      }
    `
  },

  bodyContainer: {
    bodyBackgroundColor: themeUi.semantic.color.background.neutral300,
    color: themeUi.semantic.color.icon.neutral800
  },

  hooks: {
    useInitializedStripe: {
      stripeElementsOptions: {
        colorText: themeUi.semantic.color.text.neutral800,
        colorTextPlaceholder: themeUi.semantic.color.text.neutral600,
        borderColor: themeUi.semantic.color.text.neutral500,
        fontFamily: themeUi.core.font.fontFamily.regular
      }
    }
  }
};

export default theme;
