import { UserProfileTheme } from 'themes/types/themeApp/components/userProfile.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: UserProfileTheme = {
  components: {
    sharedStyles: {
      cardBox: {
        backgroundColor: themeUi.semantic.color.background.neutral100
      },
      cardHeader: {
        color: themeUi.semantic.color.text.neutral800,
        borderBottom: themeUi.semantic.color.border.neutral400
      }
    },
    userDetailsCard: {
      fieldValue: {
        color: themeUi.semantic.color.text.neutral800
      }
    },
    mfaCard: {
      components: {
        optionCard: {
          borderColor: themeUi.semantic.color.border.neutral400
        },
        sms: {
          setupForm: {
            phoneNumberContainer: {
              telFlagDropDownBackgroundColor: themeUi.semantic.color.background.neutral100,
              selectedFlagBackgroundColor: themeUi.semantic.color.background.neutral500,
              inputFlagDropDownBackgroundColor: themeUi.semantic.color.border.neutral500,
              inputFlagDropDownErrorBackgroundColor: themeUi.semantic.color.border.danger500,
              inputFlagDropDownErrorHoverBackgroundColor: themeUi.semantic.color.border.primary400
            }
          }
        },
        authenticator: {
          components: {
            appSetupModal: {
              components: {
                bullet: {
                  bulletIndex: {
                    backgroundColor: themeUi.semantic.color.background.primary200,
                    color: themeUi.semantic.color.text.primary600
                  },
                  bulletLabel: {
                    color: themeUi.semantic.color.text.neutral800
                  }
                }
              },
              qrCodeContainer: {
                backgroundColor: themeUi.semantic.color.background.neutral200
              },
              qrManualText: {
                colorNormal: themeUi.semantic.color.text.neutral800,
                colorBold: themeUi.semantic.color.text.primary600
              }
            }
          }
        }
      }
    }
  }
};

export default theme;
