import { DbIndicatorTheme } from 'themes/types/themeApp/components/dbIndicator.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: DbIndicatorTheme = {
  statusCircleIndicator: {
    border: themeUi.semantic.color.border.neutral100
  },
  statusContainer: {
    color: themeUi.semantic.color.text.neutral800
  },
  statusItem: {
    borderBottom: themeUi.semantic.color.border.neutral400
  },

  components: {
    dBProcesses: {
      components: {
        dBProcess: {
          text: {
            color: themeUi.semantic.color.text
          }
        }
      }
    }
  }
};

export default theme;
