import { AxiosResponse } from 'axios';
import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { isEqual } from 'lodash';
import {
  AccountActionTypes,
  AccountResponse,
  UpdateAccountRequestAction,
  Account,
  SwitchAccountAction,
  CloudApiAccessResponse,
  AssignMarketplaceAccountIdRequestAction,
  AccountState,
  SetProductTypeRequestAction
} from './account.types';
import * as calls from './account.calls';
import {
  getAccountFailure,
  getAccountSuccess,
  updateAccountSuccess,
  updateAccountFailure,
  getAccountsSuccess,
  getAccountsFailure,
  createCloudApiSuccess,
  assignMarketplaceAccountIdFailure,
  setProductTypeFailureAction,
  setProductTypeSuccessAction
} from './account.actions';
import { filterPermissions, updatePermissions } from '../auth/auth.action';
import { resetStoreData } from '../store.actions';
import i18n from '../../locale/i18n';
import history from '../../hashHistory';
import {
  filterPaasPermissions,
  filterGcpPermissions,
  filterPocPermissions
} from '../../components/PermissionsProtectors/utils/filterSsoPermisions';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';
import { getAccountStateSelector } from './account.selectors';
import { callsInterval } from '../../utils/constants/api/intervals';
import { extractError } from '../../utils/helpers/extractError';
import { queryClient } from '../../utils/initializeQueryClient';
import { showToast } from '../../components/Toast/showToast';

function* getAccount() {
  const { data }: AxiosResponse<AccountResponse> = yield call(calls.getAccount);
  const [account] = data.accounts;

  return account;
}

function* resetAccount() {
  queryClient.clear();
  yield put(resetStoreData());
  history.push('/');
}

export function* getAccountSaga() {
  try {
    const account: Account = yield getAccount();
    yield put(getAccountSuccess(account));
    if (account.paas === 'Heroku' || account.paas === 'Vercel') {
      yield put(filterPermissions(filterPaasPermissions));
      yield put(updatePermissions(['TEMP_can_update_email', 'account_update']));
    }

    if (account.is_sso_gcp) {
      yield put(filterPermissions(filterGcpPermissions));
    }

    if (account.is_poc) {
      yield put(filterPermissions(filterPocPermissions));
    }

    if (account.marketplace && account.marketplace.status !== 'active') {
      yield pollAccountUntilMPIsActiveSaga();
    }
  } catch (e) {
    if (e.response) {
      yield put(getAccountFailure(e.response?.data?.message, e.response?.status));
      extractErrorAndShowToast(e);
    }
  }
}

function* pollAccountUntilMPIsActiveSaga(errorCb?: () => void) {
  const { data: accountData }: AccountState = yield select(getAccountStateSelector);

  while (true) {
    try {
      const account: Account = yield getAccount();

      if (!isEqual(accountData, account)) {
        yield put(getAccountSuccess(account));
      }

      if (account?.marketplace?.status === 'active') {
        break;
      }
    } catch (e) {
      const { message } = extractError(e?.response?.data);
      showToast(i18n.t(message));
      if (errorCb) {
        errorCb();
      }
      break;
    }
    yield delay(callsInterval);
  }
}

function* updateAccountSaga({ payload: payloadObject }: UpdateAccountRequestAction) {
  const { formPayload: payload, finallyCallback } = payloadObject;

  try {
    const { data: account }: AxiosResponse = yield call(calls.updateAccount, payload);
    yield put(updateAccountSuccess(account.account));
  } catch (e) {
    yield put(updateAccountFailure(e.response?.message));
    showToast(i18n.t('AccountSettingsScreen.errorUpdating'));
  } finally {
    finallyCallback();
  }
}

function* getAccountsSaga() {
  try {
    const { data }: AxiosResponse<{ accounts: Account[] }> = yield call(calls.getAccounts);

    yield put(getAccountsSuccess(data.accounts));
  } catch (e) {
    yield put(getAccountsFailure(e?.response?.data?.message));
    extractErrorAndShowToast(e);
  }
}

function* switchAccountSaga({ payload }: SwitchAccountAction) {
  try {
    yield call(calls.setCurrentAccount, payload);
    yield resetAccount();
  } catch (error) {
    showToast(i18n.t('navBar.userMenu.switchAccountError'));
  }
}

function* createCloudApi() {
  try {
    const {
      data: { cloudApiAccessKey }
    }: AxiosResponse<{ cloudApiAccessKey: CloudApiAccessResponse }> = yield call(
      calls.createCloudApiKey
    );

    yield put(createCloudApiSuccess(cloudApiAccessKey.accessKey));
  } catch (e) {
    extractErrorAndShowToast(e);
  }
}

function* assignAwsMarketplaceAccountIdSaga(action: AssignMarketplaceAccountIdRequestAction) {
  const { selectedAccountId, marketplaceAccountId, shouldSetAccount, successCb, errorCb } =
    action.payload;
  try {
    if (shouldSetAccount) {
      yield call(calls.setCurrentAccount, selectedAccountId);
      yield call(calls.assignMarketplaceAccountId, marketplaceAccountId);
      yield resetAccount();
    } else {
      yield call(calls.assignMarketplaceAccountId, marketplaceAccountId);
    }

    yield successCb();
    yield pollAccountUntilMPIsActiveSaga(errorCb);
  } catch (e) {
    yield put(assignMarketplaceAccountIdFailure(e?.response?.data?.message));
    const { message } = extractError(e?.response?.data);
    showToast(i18n.t(message));
    errorCb();
  }
}

function* setProductType(action: SetProductTypeRequestAction) {
  try {
    const type = action.payload;
    yield call(calls.setProduct, type);
    yield put(setProductTypeSuccessAction(type));
  } catch (e) {
    setProductTypeFailureAction();
    extractErrorAndShowToast(e);
  }
}

export default function* accountSagaWatcher() {
  yield takeLatest(AccountActionTypes.GET_ACCOUNT_REQUEST, getAccountSaga);
  yield takeLatest(AccountActionTypes.UPDATE_ACCOUNT_REQUEST, updateAccountSaga);
  yield takeLatest(AccountActionTypes.GET_ACCOUNTS_REQUEST, getAccountsSaga);
  yield takeLatest(AccountActionTypes.SWITCH_ACCOUNT, switchAccountSaga);
  yield takeLatest(AccountActionTypes.CREATE_CLOUD_API_REQUEST, createCloudApi);
  yield takeLatest(AccountActionTypes.SET_PRODUCT_TYPE_REQUEST, setProductType);
  yield takeLatest(
    AccountActionTypes.POST_MARKETPLACE_ACCOUNT_ID_REQUEST,
    assignAwsMarketplaceAccountIdSaga
  );
  yield takeLatest(AccountActionTypes.POLL_ACCOUNT_UNTIL_MP_ACTIVE, pollAccountUntilMPIsActiveSaga);
}
