import { useRef, useState, useEffect } from 'react';

interface WrapperProps {
  isVisible: boolean;
  toggleIsVisible: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapperRef: React.MutableRefObject<any>;
  coordRef: React.MutableRefObject<HTMLElement>;
}

const useDetectClickOutside = (initialState = false, useCoords = false): WrapperProps => {
  const wrapperRef = useRef(null);
  const coordRef = useRef(null);
  const [isVisible, setIsVisible] = useState(initialState);
  const toggleIsVisible = () => setIsVisible(!isVisible);
  const clickOutside = (event) => {
    if (useCoords && coordRef.current) {
      const containerCoord = coordRef.current.getBoundingClientRect();
      const mouseCoords = { x: event.clientX, y: event.clientY };
      const isInBoundaries =
        mouseCoords.x >= containerCoord.x &&
        mouseCoords.x <= containerCoord.x + containerCoord.width &&
        mouseCoords.y >= containerCoord.y &&
        mouseCoords.y <= containerCoord.y + containerCoord.height;

      return !isInBoundaries;
    }

    return wrapperRef.current && !wrapperRef.current.contains(event.target);
  };

  useEffect(() => {
    // Check that the current clicked event target is outside of the wrapper,
    // it is done by checking if the target is a child of the wrapper.
    // if not the click was outside of the wrapper, if it is, the click was inside.
    const onMouseUp = (event: MouseEvent) => {
      if (isVisible && clickOutside(event)) {
        setIsVisible(false);
      }
    };
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [isVisible]);

  return { isVisible, toggleIsVisible, wrapperRef, coordRef };
};

export default useDetectClickOutside;
