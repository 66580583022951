import React, { useEffect } from 'react';
import {
  TableOptions,
  useFilters,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { ArrowUpSlimIcon } from '@redislabsdev/redis-ui-icons';
import * as S from './Table.style';
import {
  ColumnProps,
  ControlledPaginationProps,
  TableCellProps,
  TableInstanceState,
  TableProps,
  UseTableReturn
} from './Table.types';
import { TablePagination } from './components';
import {
  defaultColumn,
  defaultSortBy,
  filterTypes as defaultFilterTypes,
  getCellTestId,
  headerProps,
  numOfRowsPerPageOptions,
  cellProps
} from './utils';
import useTableBodyHeight from './hooks/useTableBodyHeight';
import { getDataTestId } from '../../../utils';
import { isEmpty } from './utils/isEmpty';
import { TablePaginationProps } from './components/TablePagination/TablePagination.types';

const TABLE_ARROW_CUSTOM_COLOR = '#5164BD';

const Table: React.FC<TableProps> = ({
  columns,
  data,
  updateData,
  skipReset,
  onRowClick,
  className,
  initialPageSize = 10,
  controlledPaginationProps = {} as Partial<ControlledPaginationProps>,
  initialSortBy = defaultSortBy,
  manualSortBy = false,
  filterTypes = defaultFilterTypes,
  onChangeSort,
  onPageSizeChange,
  emptyStateText = 'No results found',
  noPagination,
  cellTestIdPrefix,
  ...props
}) => {
  const isPaginatedControlled = !isEmpty(controlledPaginationProps);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, filters }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      updateData,
      ...props,
      autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      autoResetFilters: !skipReset,
      autoResetSortBy: !(manualSortBy || skipReset),
      disableSortRemove: true, // When false, after one circle of sorting, sorting will removed. We want to keep sorting without removing this option
      disableMultiSort: true,
      manualSortBy,
      manualPagination: isPaginatedControlled,
      pageCount: controlledPaginationProps?.pageCount,
      initialState: {
        pageSize: initialPageSize || numOfRowsPerPageOptions[0],
        pageIndex: controlledPaginationProps?.pageIndex || 0,
        sortBy: initialSortBy
      } as TableInstanceState
    } as TableOptions<{}>,
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect
  ) as ReturnType<typeof useTable> & TablePaginationProps & UseTableReturn;
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const tableBodyHeight = useTableBodyHeight(tableContainerRef);

  React.useEffect(() => {
    onChangeSort && onChangeSort(sortBy);
  }, [sortBy]); // eslint-disable-line

  React.useEffect(() => {
    onPageSizeChange && onPageSizeChange(pageSize);
  }, [pageSize]); // eslint-disable-line

  useEffect(() => {
    if (controlledPaginationProps?.fetchData) {
      controlledPaginationProps.fetchData({ pageIndex, pageSize, sortBy });
    }
  }, [pageIndex, pageSize, sortBy]); // eslint-disable-line

  useEffect(() => {
    if (skipReset && filters?.length) {
      gotoPage(0);
    }
  }, [gotoPage, filters, skipReset]);

  const paginationProps = {
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    pageSize,
    pageIndex,
    rows,
    setPageSize,
    canPreviousPage,
    canNextPage,
    isPaginatedControlled
  };

  return (
    <S.Container className={className} ref={tableContainerRef}>
      <S.Table {...getTableProps()}>
        <S.Thead role="rowgroup" data-role="table-header">
          {headerGroups.map((headerGroupItem, idx) => (
            <div key={`table-header-${idx.toString()}`} {...headerGroupItem.getHeaderGroupProps()}>
              {headerGroupItem.headers.map((column) => {
                const {
                  style,
                  render,
                  disableSortBy,
                  isSortedDesc,
                  isSorted,
                  getHeaderProps,
                  getSortByToggleProps,
                  canFilter
                } = column as ColumnProps;

                return (
                  <S.Th
                    key={column.id}
                    isActionRow={column.id === 'actions'}
                    {...getHeaderProps((hProps) => [...headerProps(hProps, column), { style }])}
                  >
                    <S.ThContainer>
                      <div>{render('header')}</div>
                      <S.FilterContainer>
                        {!disableSortBy && (
                          <S.ArrowIndicator
                            data-testid={`icon--sort-${column.id}-column`}
                            isSortedDesc={isSortedDesc}
                            isSorted={isSorted}
                            {...getHeaderProps(getSortByToggleProps({ role: 'sort-column' }))}
                          >
                            <ArrowUpSlimIcon size="M" customColor={TABLE_ARROW_CUSTOM_COLOR} />
                          </S.ArrowIndicator>
                        )}
                        {canFilter && (
                          <span data-testid={`icon--filter-${column.id}-column`}>
                            {render('Filter')}
                          </span>
                        )}
                      </S.FilterContainer>
                    </S.ThContainer>
                  </S.Th>
                );
              })}
            </div>
          ))}
        </S.Thead>
        <S.Tbody {...getTableBodyProps()} tableBodyHeight={tableBodyHeight} data-role="table-body">
          {page.map((row) => {
            prepareRow(row);
            const originalRow = row.original as { id?: string; isNewRow?: string };

            return (
              <S.Tr
                key={row.id}
                rowData={originalRow}
                {...getDataTestId('tablerow', originalRow.id)}
                {...row.getRowProps()}
                onClick={(event: React.MouseEvent) => {
                  if (typeof onRowClick === 'function') {
                    onRowClick(row, event);
                  }
                }}
              >
                {row.cells.map((cell) => {
                  const typedCell = cell as unknown as TableCellProps;

                  return (
                    <S.Td
                      data-testid={getCellTestId(typedCell, cellTestIdPrefix)}
                      key={row.id + typedCell.column.id}
                      {...typedCell.getCellProps((cProps) => [
                        ...cellProps(cProps, typedCell),
                        { style: typedCell.column?.style }
                      ])}
                    >
                      {typedCell.render('Cell', {
                        editable: typedCell.column.editable
                      })}
                    </S.Td>
                  );
                })}
              </S.Tr>
            );
          })}
          {!rows.length && !controlledPaginationProps?.loading && (
            <div role="row">
              <S.TableExplanationText role="cell">{emptyStateText}</S.TableExplanationText>
            </div>
          )}
          {controlledPaginationProps?.loading && (
            <S.TableExplanationText>Loading... </S.TableExplanationText>
          )}
        </S.Tbody>
        {!noPagination && (
          <div role="rowgroup" data-role="table-footer">
            <div role="row">
              <div role="cell">
                <TablePagination {...paginationProps} />
              </div>
            </div>
          </div>
        )}
      </S.Table>
    </S.Container>
  );
};

export default Table;
