import React, { useEffect, useMemo } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleChevronLeftIcon,
  DoubleChevronRightIcon
} from '@redislabsdev/redis-ui-icons';
import { IconButton } from '@redislabsdev/redis-ui-components';
import { numOfRowsPerPageOptions } from '../../utils';
import * as S from './TablePagination.style';
import { TablePaginationProps } from './TablePagination.types';

const TablePagination: React.FC<TablePaginationProps> = ({
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
  pageSize,
  pageIndex,
  rows,
  setPageSize,
  canPreviousPage,
  canNextPage,
  isPaginatedControlled
}) => {
  const pageNumberOptions = useMemo(
    () => Array.from({ length: pageCount }, (_, i) => i + 1),
    [pageCount]
  );

  const handlePageSizeChange = (selectedItem) => {
    setPageSize(parseInt(selectedItem, 10));
  };

  const handlePageIndexChange = (selectedItem) => {
    const parsedSelectedItem = parseInt(selectedItem, 10);
    if (parsedSelectedItem) {
      gotoPage(parsedSelectedItem - 1);
    }
  };

  useEffect(() => {
    if (!rows.length && pageIndex !== 0) {
      gotoPage(0);
    }
  }, [gotoPage, rows, pageIndex]);

  const isDropdownDisabled =
    rows.length <= Math.min(...numOfRowsPerPageOptions) && !isPaginatedControlled;
  const isPaginationDisabled = !canNextPage && !canPreviousPage;

  return (
    <S.PaginationContainer>
      <S.DropdownContainer>
        <S.DropdownText disabled={isDropdownDisabled}>Items per page</S.DropdownText>
        <S.PageCountDropdownMenu
          options={numOfRowsPerPageOptions.map((option) => ({
            value: option.toString(),
            label: option.toString()
          }))}
          value={pageSize.toString()}
          onChange={handlePageSizeChange}
          disabled={isDropdownDisabled}
        />
      </S.DropdownContainer>

      <S.FlexRow>
        <IconButton
          icon={DoubleChevronLeftIcon}
          size="S"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          data-testid="button--navigate-to-first-page"
        />
        <IconButton
          icon={ChevronLeftIcon}
          size="S"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          data-testid="button--navigate-to-previous-page"
        />
        <S.TextContainer disabled={isPaginationDisabled}>
          <S.BoldText>{pageIndex + 1}</S.BoldText>
          {` of ${pageCount}`}
        </S.TextContainer>

        <IconButton
          icon={ChevronRightIcon}
          size="S"
          onClick={() => nextPage()}
          disabled={!canNextPage}
          data-testid="button--navigate-to-next-page"
        />
        <IconButton
          icon={DoubleChevronRightIcon}
          size="S"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          data-testid="button--navigate-to-last-page"
        />
        <S.MarginHorizontalWrapper>
          <S.DropdownContainer>
            <S.DropdownText disabled={isPaginationDisabled}>Page</S.DropdownText>
            <S.PageDropdownMenu
              options={pageNumberOptions.map((option) => ({
                value: option.toString(),
                label: option.toString()
              }))}
              value={(pageIndex + 1).toString()}
              onChange={handlePageIndexChange}
              disabled={isPaginationDisabled}
            />
            <S.DropdownText marginLeft={0.5} disabled={isPaginationDisabled}>
              {`of ${pageCount}`}
            </S.DropdownText>
          </S.DropdownContainer>
        </S.MarginHorizontalWrapper>
      </S.FlexRow>
    </S.PaginationContainer>
  );
};

export default TablePagination;
