import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import usePermissionValidation from '../components/PermissionsProtectors/hooks/usePermissionValidation';
import { showToast } from '../components/Toast/showToast';
import { accountsApi } from '../services/api/resources/accounts/accounts.resource';

export const useSupportPage = () => {
  const hasHelpDeskPermission = usePermissionValidation('account_call_helpdesk');
  const { t } = useTranslation();
  const keyPrefix = i18translation.support;

  const openSupportPage = async () => {
    if (hasHelpDeskPermission) {
      const { data } = await accountsApi.getZendeskLink();

      if (data?.url) {
        window.open(data.url, '_blank');
      }
    } else {
      showToast(t(keyPrefix.errorMessage));
    }
  };

  return { openSupportPage };
};
