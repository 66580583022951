import { parametersQueries } from 'queryClient/parameters/parameters.queries';
import { BotProtectionScript } from './BotProtectionScript/BotProtectionScript';

export const BotProtection = () => {
  const { systemParams } = parametersQueries.useSystemParameters();

  if (
    !systemParams?.human_security_bot_protection_enabled ||
    !systemParams?.human_security_bot_protection_src
  ) {
    return null;
  }

  return <BotProtectionScript script={systemParams?.human_security_bot_protection_src} />;
};
