import { SharedTheme } from 'themes/types/themeApp/components/shared.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: SharedTheme = {
  table: {
    table: {
      container: {
        backgroundColor: themeUi.semantic.color.background.neutral100,
        boxShadow: `0 0 0 1px ${themeUi.semantic.color.border.neutral600}`
      },
      th: {
        color: themeUi.semantic.color.text.neutral800,
        backgroundColor: themeUi.semantic.color.background.neutral300
      }
    },
    components: {
      tableWithActionRows: {
        tableRowButtons: {
          cancelButton: {
            hoverColor: themeUi.semantic.color.text.primary600,
            disabledBackgroundColor: themeUi.semantic.color.background.neutral500,
            disabledColor: themeUi.semantic.color.text.neutral100,
            activeColor: themeUi.semantic.color.text.neutral100
          }
        }
      },
      tablePagination: {
        paginationContainer: {
          color: themeUi.semantic.color.text.neutral600
        },
        textContainer: {
          enabledColor: themeUi.semantic.color.text.neutral600,
          disabledColor: themeUi.semantic.color.text.neutral500
        },
        dropdownText: {
          enabledColor: themeUi.semantic.color.text.neutral600,
          disabledColor: themeUi.semantic.color.text.neutral500
        }
      },
      svgFilterIcon: {
        iconButton: {
          backgroundColor: themeUi.semantic.color.background.primary200,
          hoverBackgroundColor: themeUi.semantic.color.background.primary200
        }
      },
      columnSearchFilter: {
        divider: {
          backgroundColor: themeUi.semantic.color.background.neutral400
        }
      },
      columnOptionsFilter: {
        divider: {
          backgroundColor: themeUi.semantic.color.background.neutral400
        }
      }
    }
  },

  dateTimePicker: {
    dateAndTimeField: {
      dateAndTimeFieldContainer: {
        borderBottomColor: themeUi.semantic.color.border.neutral600,
        focusBorderColor: themeUi.semantic.color.border.primary600
      },
      dateDisplay: {
        selectedColor: themeUi.semantic.color.text.neutral800,
        unselectedColor: themeUi.semantic.color.text.neutral600
      },
      iconButton: {
        color: themeUi.semantic.color.text.neutral700,
        backgroundColor: themeUi.semantic.color.text.neutral400
      }
    },
    dateAndTimePickerWrapper: {
      datePickerWrapper: {
        color: themeUi.semantic.color.text.neutral700
      },
      components: {
        datePicker: {
          datePicker: {
            selectedDateBorderAndBgColor: {
              borderColor: themeUi.semantic.color.text.primary500
            },
            datePicker: {
              dayPickerDayColor: themeUi.semantic.color.text.primary500,
              dayPickerDayTodayColor: themeUi.semantic.color.text.primary200,
              dayPickerWeekdayColor: themeUi.semantic.color.text.neutral800,
              dayPickerDayOutsideColor: themeUi.semantic.color.text.neutral500,
              dayPickerDaySelectedBackgroundColor: themeUi.semantic.color.background.primary100,
              dayPickerDaySelectedColor: themeUi.semantic.color.text.primary600,
              dayPickerDayDisabledNotColor: themeUi.semantic.color.text.neutral100,
              dayPickerDayStartFromBackground: themeUi.semantic.color.background.primary100,
              dayPickerDayStartToBackground: themeUi.semantic.color.background.neutral100,
              dayPickerDayStartBeforeBorderTop: themeUi.semantic.color.border.primary500,
              dayPickerDayStartBeforeBorderRight: themeUi.semantic.color.border.primary500,
              dayPickerDayEndFromBackground: themeUi.semantic.color.background.primary100,
              dayPickerDayEndToBackground: themeUi.semantic.color.background.neutral100,
              dayPickerDayDisabledColor: themeUi.semantic.color.text.neutral500,
              dayPickerDayBackgroundColor: themeUi.semantic.color.background.primary100
            },
            overlay: {
              border: themeUi.semantic.color.text.neutral800
            }
          },
          components: {
            datePickerFooter: {
              datePickerFooterWrapper: {
                borderTop: themeUi.semantic.color.text.neutral400
              }
            },
            datePickerHeader: {
              dateTimeCommonStyle: {
                borderBottom: themeUi.semantic.color.text.neutral400
              },
              label: {
                color: themeUi.semantic.color.text.neutral600
              }
            },
            periodSelector: {
              periodSelectorWrapper: {
                borderRight: themeUi.semantic.color.text.neutral400
              }
            }
          }
        }
      }
    },
    fallback: {
      trigger: {
        color: themeUi.semantic.color.text.neutral800
      }
    }
  },

  datePicker: {
    datePicker: {
      dayPickerWeekday: {
        color: themeUi.semantic.color.text.neutral800
      },
      dayPickerDayOutside: {
        color: themeUi.semantic.color.text.neutral500
      },
      dayPickerDaySelected: {
        color: themeUi.semantic.color.text.primary600
      },
      dayPickerDayDisabled: {
        color: themeUi.semantic.color.text.neutral500
      },
      overlay: {
        border: themeUi.semantic.color.border.neutral800
      }
    }
  },

  card: {
    cardContainer: {
      backgroundColor: themeUi.semantic.color.background.neutral100,
      borderTop: themeUi.semantic.color.border.neutral400
    },
    listGroupItem: {
      borderBottom: themeUi.semantic.color.border.neutral400
    },
    title: {
      color: themeUi.semantic.color.text.neutral800
    }
  },

  charts: {
    barChart: {
      container: {
        colors: themeUi.semantic.color.icon
      }
    },

    lineChart: {
      lineChart: {
        referenceLineStroke: themeUi.semantic.color.icon.neutral800,
        lineStroke: themeUi.semantic.color.icon.neutral800,
        responsiveContainerWrapper: {
          stroke: themeUi.semantic.color.icon.primary500
        }
      },

      components: {
        customLegend: {
          legend: {
            backgroundColor: themeUi.semantic.color.background.neutral100
          }
        },

        customTooltip: {
          tooltipContainer: {
            backgroundColor: themeUi.semantic.color.background.neutral100
          }
        }
      }
    },

    chartContainer: {
      stroke: themeUi.semantic.color.icon.primary500
    }
  },

  collapse: {
    components: {
      collapseCompose: {
        collapseContainer: {
          border: `none`,
          fill: themeUi.semantic.color.text.neutral600,
          background: themeUi.semantic.color.text.neutral300,
          borderTop: themeUi.semantic.color.text.neutral600,
          color: themeUi.semantic.color.text.neutral700,
          fontFamily: themeUi.core.font.fontFamily.regular
        }
      }
    }
  },

  copyText: {
    circle: {
      backgroundColor: themeUi.semantic.color.background.success500
    },
    copySuccessContainer: {
      color: themeUi.semantic.color.text.success500
    }
  },

  iconCard: {
    cardUnderText: {
      color: themeUi.semantic.color.text.primary400
    },
    whiteCircle: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    },
    card: {
      backgroundColor: themeUi.semantic.color.background.neutral100,
      border: themeUi.semantic.color.border.success500
    },
    disabledText: {
      color: themeUi.semantic.color.text.primary300
    },
    linkText: {
      color: themeUi.semantic.color.text.primary600
    },
    linkUnderlay: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    },
    linkIconBackground: {
      borderColor: themeUi.semantic.color.border.primary600
    }
  },

  inputControl: {
    label: {
      disabledColor: themeUi.semantic.color.text.neutral500,
      enabledColor: themeUi.semantic.color.text.neutral700
    }
  },

  labelValueItem: {
    valueText: {
      color: themeUi.semantic.color.text
    },
    labelText: {
      color: themeUi.semantic.color.text
    }
  },

  selectionCard: {
    selectionCard: {
      backgroundColor: themeUi.semantic.color.background.neutral100,
      border: themeUi.semantic.color.border.notice500,
      beforeBorderTopColor: themeUi.semantic.color.border.notice500,
      afterBorderTopColor: themeUi.semantic.color.border.neutral100
    },
    cardLabel: {
      backgroundColor: themeUi.semantic.color.background.notice500
    },
    cardTitle: {
      color: themeUi.semantic.color.text.neutral800
    },
    cardBodyTitle: {
      color: themeUi.semantic.color.text.neutral600
    },
    cardBodyTextUl: {
      color: themeUi.semantic.color.text.neutral600
    },
    CardBodySubTextUl: {
      color: themeUi.semantic.color.text.success500
    },
    cardFooterText: {
      color: themeUi.semantic.color.text.notice500
    },
    cardFooterSubText: {
      color: themeUi.semantic.color.text.notice500
    }
  },

  slider: {
    embiggenCircle: {
      border: themeUi.semantic.color.border.neutral100
    },
    optionCircle: {
      border: themeUi.semantic.color.border.neutral100
    },
    ball: {
      backgroundColor: themeUi.semantic.color.background.success500
    },
    optionText: {
      selectedColor: themeUi.semantic.color.text.neutral100,
      unselectedColor: themeUi.semantic.color.text.primary300,
      hoverColor: themeUi.semantic.color.text.neutral100
    },
    line: {
      backgroundColor: themeUi.semantic.color.text.neutral100
    }
  },

  tableFilters: {
    columnTimeRangeFilter: {
      arrowIcon: {
        background: themeUi.semantic.color.background.neutral100
      },
      clearAllText: {
        colors: themeUi.semantic.color.text
      },
      divider: {
        backgroundColor: themeUi.semantic.color.background.neutral400
      },
      filterIconContainer: {
        background: themeUi.semantic.color.background.primary200
      }
    }
  },

  tempFilterIcon: {
    backgroundColor: themeUi.semantic.color.background.primary200
  }
};

export default theme;
