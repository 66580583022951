import styled from 'styled-components/macro';
import { FormField } from '@redislabsdev/redis-ui-components';

export const UnderInputText = styled.p`
  margin-top: 0.5rem;
  color: ${({ theme }) =>
    theme.appComponents.components.formControls.formInputs.formInput.underInputText.color};
  font-size: 1.2rem;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const FlexContainer = styled.span`
  display: flex;
`;

export const FormFieldCompose = styled(FormField.Compose)`
  flex: 1;
  &:last-child:not(:only-child) {
    margin-left: 1.8rem;
  }
`;
