import RedisApiAxiosInstance from 'services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { Config } from '../../config';

const initDatabasesMFEApiService = async () => {
  try {
    // @ts-ignore
    const { initSmApiService, initDatabasesApiService } = await import('databases/initApiService');
    initSmApiService(RedisApiAxiosInstance, !Config.IS_RELATIVE_API && Config.CURRENT_ORIGINS.SM);
    initDatabasesApiService(
      RedisApiAxiosInstance,
      !Config.IS_RELATIVE_API && Config.CURRENT_ORIGINS.DATABASE
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('cannot load initDatabasesMFEApiService', e);
  }
};

export default initDatabasesMFEApiService;
