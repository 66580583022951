import { ComponentsTheme } from 'themes/types/themeApp/components/components.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: ComponentsTheme = {
  readMore: {
    color: themeUi.semantic.color.border.primary600
  },

  themeSwitch: {
    themeSwitch: {
      borderColor: themeUi.semantic.color.border.neutral600,
      backgroundColor: themeUi.semantic.color.border.neutral300
    }
  },

  priceBreakDown: {
    footerContainer: {
      borderTop: themeUi.semantic.color.border.neutral500
    }
  },

  aaRedisLabsPrice: {
    aaRedisLabsDifference: {
      aaRedisLabsPrice: {
        border: themeUi.semantic.color.border.neutral800
      }
    }
  },

  accountAddressDrawer: {
    accountAddressDrawer: {
      sectionContainer: {
        borderBottom: themeUi.semantic.color.border.neutral400
      },
      sectionHeader: {
        color: themeUi.semantic.color.text.neutral800
      }
    },

    components: {
      creditCardSection: {
        creditCardSection: {
          reenterCCNotificationContainer: {
            backgroundColor: themeUi.semantic.color.background.attention100
          },
          creditCardContainer: {
            backgroundColor: themeUi.semantic.color.background.primary50,
            border: themeUi.semantic.color.border.neutral500
          }
        },

        components: {
          previouslyUsedCC: {
            previousCCWrapper: {
              color: themeUi.semantic.color.text.primary600
            }
          }
        }
      },

      termsOfUse: {
        tosExplanationText: {
          color: themeUi.semantic.color.text.neutral800
        },
        paymentProviderExplanationText: {
          color: themeUi.semantic.color.text.neutral600
        },
        divider: {
          borderLeft: themeUi.semantic.color.border.neutral800
        }
      }
    }
  },

  addCreditCardButton: {
    plusContainer: {
      color: themeUi.semantic.color.text.primary400
    },
    plusButton: {
      backgroundColor: themeUi.semantic.color.background.primary600
    }
  },

  addMarketplaceAccountButton: {
    plusContainer: {
      color: themeUi.semantic.color.text.primary400
    },
    plusButton: {
      backgroundColor: themeUi.semantic.color.background.primary600
    }
  },

  addEditCCDialog: {
    components: {
      inputFields: {
        readyOnlyCCDetails: {
          color: themeUi.semantic.color.text.neutral800
        }
      },

      sections: {
        accountAddress: {
          components: {
            readOnlyAddress: {
              components: {
                readOnlyRow: {
                  readOnlyInput: {
                    color: themeUi.semantic.color.text.neutral800
                  }
                }
              }
            }
          }
        },

        sectionHeader: {
          color: themeUi.semantic.color.text.neutral800
        }
      }
    }
  },

  availabilityAzsSelectWrapper: {
    components: {
      availabilityAzSelect: {
        note: {
          color: themeUi.semantic.color.text.neutral600
        }
      }
    }
  },

  banner: {
    variantStyles: {
      noticeBackgroundColor: themeUi.semantic.color.background.notice500,
      noticeColor: themeUi.semantic.color.text.neutral100,
      dangerBackgroundColor: themeUi.semantic.color.background.danger500,
      dangerColor: themeUi.semantic.color.text.neutral100
    }
  },

  contractCard: {
    components: {
      contractCardView: {
        contractTitle: {
          color: themeUi.semantic.color.text.neutral800
        },
        label: {
          color: themeUi.semantic.color.text.neutral600
        },
        value: {
          color: themeUi.semantic.color.text.neutral700
        },
        paymentMethodContainer: {
          borderTop: themeUi.semantic.color.border.neutral400
        }
      }
    }
  },

  crdbInstancesThroughput: {
    opsText: {
      color: themeUi.semantic.color.text.neutral800
    },
    totalThroughputRow: {
      borderTop: themeUi.semantic.color.border.neutral400,
      borderBottom: themeUi.semantic.color.border.neutral500
    }
  },

  customPaymentOption: {
    ccNumber: {
      color: themeUi.semantic.color.text.neutral800
    }
  },

  databaseIcon: {
    emptyDbIcon: {
      selectedFill: themeUi.semantic.color.icon.primary500,
      unselectedFill: themeUi.semantic.color.icon.neutral700
    }
  },

  editableList: {
    lists: {
      draggableList: {
        components: {
          droppableList: {
            components: {
              draggableItem: {
                item: {
                  backgroundColor: themeUi.semantic.color.background.primary100
                },
                index: {
                  color: themeUi.semantic.color.text.primary400
                },
                grip: {
                  color: themeUi.semantic.color.icon.neutral500
                }
              }
            }
          }
        }
      }
    },

    styles: {
      sharedList: {
        listContainer: {
          border: themeUi.semantic.color.border.neutral400
        }
      },

      sharedListItem: {
        listItem: {
          color: themeUi.semantic.color.text.neutral300
        }
      }
    }
  },

  emptyListsFallback: {
    label: {
      color: themeUi.semantic.color.text.neutral700,
      fontSize: themeUi.core.font.fontSize
    },
    description: {
      color: themeUi.semantic.color.text.neutral700,
      fontSize: themeUi.core.font.fontSize
    }
  },

  expandableTable: {
    column: {
      canExpandColor: themeUi.semantic.color.text.neutral700,
      cantExpandColor: themeUi.semantic.color.text.neutral600
    }
  },

  essentialPlanSelection: {
    components: {
      planDescription: {
        planDescription: {
          container: {
            backgroundColor: themeUi.semantic.color.background.neutral100
          },
          horizontalLine: {
            backgroundColor: themeUi.semantic.color.background.neutral600
          },
          taxesExplanation: {
            color: themeUi.semantic.color.text.neutral600
          }
        },

        components: {
          planDetails: {
            planDetails: {
              textColor: {
                color: themeUi.semantic.color.text.neutral800
              },
              separator: {
                backgroundColor: themeUi.semantic.color.background.neutral800
              }
            },

            components: {
              payAsYouGoInfo: {
                title: {
                  color: themeUi.semantic.color.text.neutral800
                },
                text: {
                  color: themeUi.semantic.color.text.neutral600
                }
              }
            }
          },

          planProperties: {
            propertiesRow: {
              borderBottom: themeUi.semantic.color.border.neutral400
            },
            propertyTextValue: {
              color: themeUi.semantic.color.text.neutral700
            }
          }
        }
      },

      planList: {
        planCard: {
          planCost: {
            color: themeUi.semantic.color.text.neutral700
          },
          alreadyInUseText: {
            color: themeUi.semantic.color.text.neutral600
          },
          selectedAndHoverStyle: {
            color: themeUi.semantic.color.text.neutral800
          },
          container: {
            backgroundColor: themeUi.semantic.color.background.neutral100,
            color: themeUi.semantic.color.text.neutral600
          },
          banner: {
            color: themeUi.semantic.color.text.primary600
          }
        }
      }
    }
  },

  ccMiniCard: {
    components: {
      switchCcCard: {
        components: {
          ccSelection: {
            buttonContainer: {
              color: themeUi.semantic.color.text.primary400
            }
          }
        }
      },

      creditCardOptionsList: {
        paymentOptionList: {
          borderTop: themeUi.semantic.color.border.neutral400
        },
        horizontalLine: {
          backgroundColor: themeUi.semantic.color.background.neutral400
        },
        optionContainer: {
          backgroundColor: themeUi.semantic.color.background.neutral400
        }
      },

      ccMiniCardCommonWrapper: {
        marketplaceDisplayId: {
          color: themeUi.semantic.color.text.neutral700,
          fontFamily: themeUi.core.font.fontFamily.regular
        },
        marketplacePlan: {
          color: themeUi.semantic.color.text.neutral800
        }
      },

      ccMiniCardView: {
        circle: {
          backgroundColor: themeUi.semantic.color.background.primary500,
          hoverBackgroundColor: themeUi.semantic.color.background.primary200
        },
        footerItemTitle: {
          color: themeUi.semantic.color.text.neutral600
        },
        footerItemValue: {
          color: themeUi.semantic.color.text.neutral700
        },
        miniCardBody: {
          color: themeUi.semantic.color.text.neutral800
        },
        card: {
          backgroundColor: themeUi.semantic.color.background.neutral100
        },
        placeholder: {
          backgroundColor: themeUi.semantic.color.background.neutral500
        }
      }
    }
  },

  formControls: {
    formInputs: {
      formInput: {
        underInputText: {
          color: themeUi.semantic.color.text.neutral800
        }
      }
    },

    inputLabelWithInfo: {
      optional: {
        color: themeUi.semantic.color.text.neutral500
      }
    },

    viewPasswordField: {
      password: {
        color: themeUi.semantic.color.text.neutral800
      }
    }
  },

  freeTrialGlobalBanner: {
    separator: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    }
  },

  freeTrialRce: {
    components: {
      freeTrialRceExistingUsersModal: {
        upgradingBenefitsTitle: {
          color: themeUi.semantic.color.text.neutral800,
          marginTop: themeUi.core.space.space300
        },
        benefitList: {
          marginTop: themeUi.core.space.space100
        }
      }
    }
  },

  hiddenListNumber: {
    hoverText: {
      color: themeUi.semantic.color.text.neutral800
    }
  },

  highAvailability: {
    contentContainer: {
      color: themeUi.semantic.color.text.neutral100
    },
    multiZoneText: {
      color: themeUi.semantic.color.text.neutral100
    },
    readMore: {
      color: themeUi.semantic.color.text.neutral100
    }
  },

  loginRelatedComponents: {
    commonContainer: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    },
    titleCss: {
      color: themeUi.semantic.color.text.neutral700
    },
    checkEmailText: {
      color: themeUi.semantic.color.text.neutral700
    },
    clickMessage: {
      color: themeUi.semantic.color.text.neutral700
    }
  },

  memoryAlert: {
    container: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    }
  },

  modalCoverLoader: {
    modalVocerLoadingContainer: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    }
  },

  networkConfig: {
    inputContainer: {
      color: themeUi.semantic.color.text.neutral600
    },
    label: {
      color: themeUi.semantic.color.text.neutral700
    }
  },

  optionBadgeList: {
    optionBadgeItem: {
      border: themeUi.semantic.color.border.neutral100,
      color: themeUi.semantic.color.text,
      backgroundColor: themeUi.semantic.color.background
    }
  },

  pocAccountAlert: {
    pocBannerContainer: {
      superAgentColor: themeUi.semantic.color.text.neutral100,
      notSuperAgentColor: themeUi.semantic.color.text.notice500
    }
  },

  priceValue: {
    priceValueContainer: {
      color: themeUi.semantic.color.text.neutral800
    }
  },

  prices: {
    priceRow: {
      color: themeUi.semantic.color.text.neutral800,
      borderBottom: themeUi.semantic.color.border.neutral400,
      borderBottomColor: themeUi.semantic.color.border.neutral500
    },
    priceExplanation: {
      color: themeUi.semantic.color.text.neutral600
    },
    bannerContainer: {
      backgroundColor: themeUi.semantic.color.background.attention100,
      color: themeUi.semantic.color.background.attention600
    },
    bannerText: {
      color: themeUi.semantic.color.text.attention700,
      fontFamily: themeUi.core.font.fontFamily.regular
    },
    bannerBoldText: {
      color: themeUi.semantic.color.text.attention700
    }
  },

  propertyStatus: {
    disabledProperty: {
      color: themeUi.semantic.color.text.neutral500
    }
  },

  radioButtonGroup: {
    radioButtonsContainer: {
      color: themeUi.semantic.color.text
    }
  },

  redisInsightConnectionModal: {
    heading: {
      color: themeUi.semantic.color.text.neutral800
    },
    clickHereAnchor: {
      color: themeUi.semantic.color.text.primary600
    },
    endpointSelectionContainer: {
      borderBottom: themeUi.semantic.color.border.neutral400
    }
  },

  regionDisplayField: {
    label: {
      color: themeUi.semantic.color.text.neutral700
    },
    regionValue: {
      color: themeUi.semantic.color.text.neutral800
    },
    regionContainer: {
      color: themeUi.semantic.color.text.neutral700,
      strongColor: themeUi.semantic.color.text.neutral800
    }
  },

  regionDisplayOption: {
    regionOptionContainer: {
      color: themeUi.semantic.color.text.primary600
    }
  },

  regionSelect: {
    title: {
      color: themeUi.semantic.color.text.neutral700
    },
    regionDisplayField: {
      color: themeUi.semantic.color.text.neutral700
    }
  },

  regionSelectList: {
    components: {
      hiddenRegionListToggle: {
        hiddenRegionListToggle: {
          toggleButton: {
            backgroundColor: themeUi.semantic.color.background.neutral100,
            color: themeUi.semantic.color.text.primary600,
            hoverBackgroundColor: themeUi.semantic.color.background.primary200,
            menuOpenBackgroundColor: themeUi.semantic.color.background.primary500,
            menuOpenColor: themeUi.semantic.color.text.neutral100
          }
        },

        tooltipMenuContent: {
          menuContent: {
            list: {
              color: themeUi.semantic.color.text.neutral800
            },
            listItem: {
              backgroundColor: themeUi.semantic.color.background.neutral400
            }
          }
        }
      },

      regionSelectCard: {
        regionSelectCard: {
          card: {
            backgroundColor: themeUi.semantic.color.background.neutral100,
            borderTop: themeUi.semantic.color.border.notice500,
            disabledBackgroundColor: themeUi.semantic.color.background.neutral200
          },
          regionName: {
            color: themeUi.semantic.color.text.neutral800
          }
        },

        components: {
          regionAction: {
            action: {
              border: themeUi.semantic.color.border.neutral800
            }
          },

          regionIndication: {
            indication: {
              backgroundColor: themeUi.semantic.color.background.neutral100
            }
          }
        }
      }
    }
  },

  requiredCloudResources: {
    components: {
      cloudResourcesHeader: {
        cloudAccountName: {
          color: themeUi.semantic.color.text.neutral600
        },
        totalPriceContainer: {
          color: themeUi.semantic.color.text.neutral800
        }
      }
    }
  },

  stepTab: {
    list: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    },
    stepNumber: {
      backgroundColor: themeUi.semantic.color.background.primary100
    },
    listItem: {
      color: themeUi.semantic.color.text.primary400,
      activeColor: themeUi.semantic.color.text.neutral100,
      activeBackgroundColor: themeUi.semantic.color.background.primary400,
      activeStepNumberBackgroundColor: themeUi.semantic.color.background.primary500,
      activeHoverColor: themeUi.semantic.color.text.neutral100,
      hoverColor: themeUi.semantic.color.text.primary500,
      hoverStepNumberBackgroundColor: themeUi.semantic.color.background.primary500,
      hoverStepNumberColor: themeUi.semantic.color.text.neutral100
    }
  },

  topBarErrorBoundary: {
    errorInformationText: {
      backgroundColor: themeUi.semantic.color.background.danger500
    }
  },

  trafficLightStatus: {
    trafficLightStatus: {
      circle: {
        backgroundColor: themeUi.semantic.color.background,
        color: themeUi.semantic.color.text.neutral100
      }
    },

    components: {
      bigStatusComponent: {
        emptyCircle: {
          backgroundColor: themeUi.semantic.color.background,
          color: themeUi.semantic.color.text.neutral800
        }
      }
    }
  },

  validateCookiesEnabled: {
    wrapper: {
      backgroundColor: themeUi.semantic.color.background.neutral300
    },
    contentContainer: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    },
    headingText: {
      color: themeUi.semantic.color.text.neutral800
    }
  },

  wistiaPlayer: {
    backgroundColor: themeUi.semantic.color.background.neutral300,
    playerColor: themeUi.semantic.color.text.neutral700
  },

  wrappedTable: {
    tableHeader: {
      borderBottom: themeUi.semantic.color.border.neutral400
    },
    tableTitle: {
      color: themeUi.semantic.color.text.neutral800
    }
  },

  oldPricing: {
    components: {
      oldRequiredCloudResources: {
        subTitle: {
          color: themeUi.semantic.color.text.neutral600
        }
      }
    }
  }
};

export default theme;
