import { DataAccessControlTheme } from 'themes/types/themeApp/components/dataAccessControl.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: DataAccessControlTheme = {
  styledModalParagraph: {
    color: themeUi.semantic.color.text.neutral700
  },

  components: {
    tooltips: {
      noRoleTooltip: {
        tooltipHeader: {
          backgroundColor: themeUi.semantic.color.background.primary50
        },
        title: {
          color: themeUi.semantic.color.text.neutral700
        },
        body: {
          color: themeUi.semantic.color.text.neutral800
        },
        tooltipFooter: {
          borderTop: themeUi.semantic.color.border.neutral400,
          backgroundColor: themeUi.semantic.color.background.neutral100
        }
      }
    }
  },

  redisAcls: {
    ellipsisTooltipTableCell: {
      normalColor: themeUi.semantic.color.text.neutral700,
      disabledColor: themeUi.semantic.color.text.neutral500
    },

    components: {
      cells: {
        supportedVersionsCell: {
          minimumVersion: {
            normalColor: themeUi.semantic.color.text.neutral700,
            disabledColor: themeUi.semantic.color.text.neutral500
          }
        },
        aclRuleCell: {
          components: {
            aclRuleValidationErrorDisplay: {
              container: {
                color: themeUi.semantic.color.text.danger500
              }
            }
          }
        },
        aclNameCell: {
          pendingCircleStatus: {
            pendingStatus: {
              backgroundColor: themeUi.semantic.color.background.attention500
            }
          }
        }
      },
      downloadRedisInsight: {
        tooltipContent: {
          color: themeUi.semantic.color.text.neutral800
        }
      },
      aclsDocLink: {
        aclsDocLink: {
          color: themeUi.semantic.color.text.primary600
        }
      },
      aclV2Banner: {
        bannerContainer: {
          border: themeUi.semantic.color.border.primary300,
          backgroundColor: themeUi.semantic.color.background.primary50,
          color: themeUi.semantic.color.text.primary700,
          aColor: themeUi.semantic.color.text.primary700
        }
      }
    }
  },

  newRoles: {
    components: {
      roleManager: {
        drawerSectionTitle: {
          color: themeUi.semantic.color.text.neutral800
        },
        divider: {
          backgroundColor: themeUi.semantic.color.background.neutral400
        },
        loaderContainer: {
          backgroundColor: themeUi.semantic.color.background.neutral100
        },
        inputLabel: {
          normalColor: themeUi.semantic.color.text.neutral800,
          selectedColor: themeUi.semantic.color.text.neutral500
        },
        inputField: {
          color: themeUi.semantic.color.text.neutral800
        },

        components: {
          associationsSection: {
            components: {
              associationsTable: {
                components: {
                  subscriptionTitleViewMode: {
                    subscriptionHeaderContainer: {
                      color: themeUi.semantic.color.text.primary600
                    }
                  }
                },

                containerWrapper: {
                  border: themeUi.semantic.color.border.neutral400,
                  emptyBackgroundColor: themeUi.semantic.color.background.neutral200,
                  fullBackgroundColor: themeUi.semantic.color.background.neutral100,
                  viewModeCOlor: themeUi.semantic.color.text.neutral700
                },

                column: {
                  canExpandColor: themeUi.semantic.color.text.neutral700,
                  cantExpandColor: themeUi.semantic.color.text.neutral600
                },

                row: {
                  borderBottom: themeUi.semantic.color.border.primary200,
                  canExpandBackgroundColor: themeUi.semantic.color.background.neutral100,
                  cantExpandBackgroundColor: themeUi.semantic.color.background.primary50
                },

                regionDisplayField: {
                  color: themeUi.semantic.color.text.neutral700
                },

                aclRulesDropdownMenu: {
                  backgroundColor: themeUi.semantic.color.background.neutral400
                },

                databaseHeaderText: {
                  normalColor: themeUi.semantic.color.text.primary600,
                  subscriptionColor: themeUi.semantic.color.text.neutral800
                },

                aclViewModeText: {
                  color: themeUi.semantic.color.text.neutral800
                }
              }
            }
          },
          roleEditor: {
            roleEditor: {
              smDrawerTitle: {
                fontFamily: themeUi.core.font.fontFamily.regular
              }
            },

            components: {
              roleEditorBody: {
                components: {
                  databasesSection: {
                    filterDatabasesText: {
                      color: themeUi.semantic.color.text.neutral600
                    },
                    components: {
                      multiTreeSelect: {
                        headerContainer: {
                          borderColor: themeUi.semantic.color.border.neutral600,
                          color: themeUi.semantic.color.text.neutral800,

                          disabledBackgroundColor: themeUi.semantic.color.background.neutral200,
                          disabledBorderColor: themeUi.semantic.color.border.neutral400,
                          disabledColor: themeUi.semantic.color.text.neutral600,

                          focusBorderColor: themeUi.semantic.color.border.primary500,

                          visibleBorderColor: themeUi.semantic.color.border.primary500,

                          errorBorderColor: themeUi.semantic.color.border.danger500,
                          errorFocusedBorderColor: themeUi.semantic.color.border.danger500
                        },
                        listItemContainer: {
                          backgroundColor: themeUi.semantic.color.background.neutral400
                        },
                        list: {
                          boxShadow: themeUi.core.shadow.shadow2
                        },
                        checkBoxInput: {
                          backgroundColor: themeUi.semantic.color.background.neutral500,
                          checkedDisabledBeforeBackgroundColor:
                            themeUi.semantic.color.background.neutral500,
                          checkedDisabledBackgroundColor:
                            themeUi.semantic.color.background.neutral500
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export default theme;
