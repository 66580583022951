import { UseQueryOptions } from '@tanstack/react-query';
import { paymentsInfosApi } from '../../services/api/resources/paymentInfos/paymentInfos.resource';
import paymentInfosKeys from './paymentInfos.keys';
import { IPaymentInfo } from '../../store/paymentMethods/paymentMethods.types';
import useSmQuery from '../useSmQuery';

const useCountriesQuery = (queryOptions: UseQueryOptions<CountriesData> = {}) => {
  const getCountries = async () => {
    const { data } = await paymentsInfosApi.getCountriesData();

    return data;
  };

  const queryResult = useSmQuery({
    permissionKey: false,
    queryKey: paymentInfosKeys.getAllCountries,
    queryFn: getCountries,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions
  });

  return {
    ...queryResult,
    data: queryResult.data || { countries: [], states: [] }
  };
};

const usePaymentInfoQuery = (
  paymentInfoId: number,
  queryOptions: UseQueryOptions<IPaymentInfo> = {}
) => {
  const getPaymentInfo = async () => {
    const { data } = await paymentsInfosApi.getOne(paymentInfoId);

    return data.paymentInfo;
  };

  return useSmQuery({
    permissionKey: 'paymentinfo_get_payment',
    queryKey: paymentInfosKeys.getPaymentInfo(paymentInfoId),
    queryFn: getPaymentInfo,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions
  });
};

const useAllowedMarketplacesQuery = (queryOptions: UseQueryOptions<MarketplaceInfo[]> = {}) => {
  const getAllowedMarketplaces = async () => {
    const { data } = await paymentsInfosApi.getAllowedMarketplaces();

    return data;
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: paymentInfosKeys.getAllowedMarketplaces,
    queryFn: getAllowedMarketplaces,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...queryOptions
  });
};

const useCurrentMarketplaceQuery = (queryOptions: UseQueryOptions<MarketplaceInfo> = {}) => {
  const getCurrentMarketplace = async () => {
    const { data } = await paymentsInfosApi.getCurrentMarketplace();

    return data;
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: paymentInfosKeys.getCurrentMarketplace,
    queryFn: getCurrentMarketplace,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...queryOptions
  });
};

export const paymentInfosQueries = {
  useCountries: useCountriesQuery,
  usePaymentInfo: usePaymentInfoQuery,
  useAllowedMarketplaces: useAllowedMarketplacesQuery,
  useCurrentMarketplace: useCurrentMarketplaceQuery
};
