import { ApiPaths } from 'services/api/apiPaths';
import { Account } from 'store/account/account.types';
import { createResourceApi } from '../../createResourceApi';

export const accountsApi = createResourceApi(ApiPaths.ACCOUNTS, (rest) => ({
  getAll: (params?: GetAccountsParams) => rest.get<{ accounts: Account[] }>({ config: { params } }),
  getZendeskLink: () => rest.get<AccountZendeskUrl>({ path: 'actions/helpdesk-url' }),
  getDownloadCertificateAuthority: () => rest.get<string>({ path: 'actions/get-ca' }),
  updateBusinessDetails: (accountID: number, businessDetails: BusinessDetails) =>
    rest.put<BusinessDetails>({
      path: `${accountID}/business-details`,
      data: businessDetails
    }),
  postAssignMarketplaceMultipleAccounts: (body: AssignMarketplaceMultipleAccountsBody) =>
    rest.post({ path: 'assign-marketplace-multiple-accounts', data: [...body.accountIds] })
}));
