import { TopBarTheme } from 'themes/types/themeApp/components/topBar.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: TopBarTheme = {
  components: {
    envBanner: {
      hrefText: {
        color: themeUi.semantic.color.text.neutral100,
        colors: themeUi.semantic.color.text
      },
      iconContainer: {
        fill: themeUi.semantic.color.icon.neutral100
      },
      verticalLine: {
        backgroundColor: themeUi.semantic.color.text.neutral100
      }
    },

    userMenu: {
      userMenu: {
        nameAndArrowContainer: {
          notifyColor: themeUi.semantic.color.text.neutral100,
          unnotifyColor: themeUi.semantic.color.text.neutral800
        },
        userName: {
          notifyColor: themeUi.semantic.color.text.neutral100,
          unnotifyColor: themeUi.semantic.color.text.neutral700,
          fontSize: themeUi.core.font.fontSize.s12
        },
        menuContainer: {
          notifyColor: themeUi.semantic.color.text.neutral100,
          unnotifyColor: themeUi.semantic.color.text.primary600,

          notifyBackgroundColor: themeUi.color.pink500,
          unnotifyBackgroundColor: themeUi.semantic.color.background.primary50,

          fill: themeUi.semantic.color.icon.primary600
        },
        arrowIcon: {
          fill: themeUi.semantic.color.icon.neutral800
        }
      },

      components: {
        userMenuContent: {
          userMenuContent: {
            container: {
              color: themeUi.semantic.color.text.neutral800
            },
            editProfileIcon: {
              fill: themeUi.semantic.color.icon.neutral700
            },
            signOutIcon: {
              stroke: themeUi.semantic.color.icon.neutral700
            },
            menuActionItem: {
              borderTop: themeUi.semantic.color.border.neutral400,
              backgroundColor: themeUi.semantic.color.text.neutral300
            },
            userName: {
              color: themeUi.semantic.color.text.neutral700
            }
          },

          components: {
            switchAccount: {
              accountNameAndId: {
                selectedColor: themeUi.semantic.color.text.primary600,
                unselectedColor: themeUi.semantic.color.text.neutral700,
                gap: themeUi.core.space.space100
              },
              accountItem: {
                backgroundColor: themeUi.semantic.color.background.neutral300
              }
            }
          }
        }
      }
    }
  }
};

export default theme;
