export function sortById<T extends { id?: number }>(arr: T[], isAsc = false) {
  const orderNum = isAsc ? -1 : 1;

  return arr.sort((a, b) => {
    if (a.id < b.id) {
      return orderNum;
    }
    if (b.id < a.id) {
      return -orderNum;
    }

    return 0;
  });
}

export const getMajorMinor = (v?: string) => {
  if (!v) {
    return null;
  }
  const versionArr = v.split('-')[0].split('.');
  const majorVersion = versionArr[0] || 0;
  const minorVersion = versionArr[1] || 0;

  return `${majorVersion}.${minorVersion}`;
};
