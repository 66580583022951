import { createContext, ReactNode, useMemo } from 'react';
import { ThemeName } from 'themes/hooks/useTheme.types';

type ContextProps = {
  themeName: ThemeName;
  setThemeName: (themeName: ThemeName) => void;
};

export const ThemeSwitchContext = createContext<ContextProps | null>(null);

interface ThemeSwitchProviderProps extends ContextProps {
  children: ReactNode;
}

const ThemeSwitchProvider = ({ children, themeName, setThemeName }: ThemeSwitchProviderProps) => {
  const contextValue = useMemo(() => ({ themeName, setThemeName }), [themeName, setThemeName]);

  return <ThemeSwitchContext.Provider value={contextValue}>{children}</ThemeSwitchContext.Provider>;
};

export default ThemeSwitchProvider;
