import { toast, ToastActionType } from '@redislabsdev/redis-ui-components';
import { errorsMap, GENERAL_ERROR_CODE, ErrorsMapCodes } from '../constants/api/errorsMap';
import { ErrorInfo, ExtendedAxiosError } from '../../types/interfaces';
import { dialogIndicatorContextGlobal } from './dialogIndicatorContext';
import i18n from '../../locale/i18n';

export const extractError = (data): ErrorInfo => {
  const code = extractErrorCode(data);

  return errorsMap[code] ? errorsMap[code] : errorsMap[GENERAL_ERROR_CODE];
};

export const extractErrorCode = (data): ErrorsMapCodes => {
  let code;

  if (data?.errors && data?.errors?.code) {
    code = data.errors.code;
  } else if (data?.errors) {
    const errors = typeof data.errors === 'string' ? JSON.parse(data.errors) : data.errors;
    if (Array.isArray(errors) && errors[0]?.error_code) {
      code = errors[0].error_code;
    } else {
      code = GENERAL_ERROR_CODE;
    }
  } else {
    code = GENERAL_ERROR_CODE;
  }

  return code;
};

export const extractErrors = (data): ErrorInfo[] => {
  let errorInfo;

  if (data?.errors && data?.errors?.code) {
    errorInfo = [errorsMap[GENERAL_ERROR_CODE]];
  } else if (data?.errors) {
    const errors = typeof data.errors === 'string' ? JSON.parse(data.errors) : data.errors;
    if (Array.isArray(errors) && errors[0]?.error_code) {
      errorInfo = errors.map((error) => errorsMap[error.error_code]);
    } else {
      errorInfo = [errorsMap[GENERAL_ERROR_CODE]];
    }
  } else {
    errorInfo = [errorsMap[GENERAL_ERROR_CODE]];
  }

  return errorInfo;
};

export const handleErrorDisplay = (e: ExtendedAxiosError, primaryAction?: ToastActionType) => {
  const modal = document.querySelectorAll('[data-role="dialog-hub"]');
  const { message } = extractError(e?.response?.data);
  const errMessage = message || '';

  if (modal.length > 0) {
    dialogIndicatorContextGlobal.setIndicator?.({
      text: i18n.t(errMessage),
      type: 'warning'
    });
  } else {
    toast.danger({
      description: i18n.t(message),
      ...(primaryAction ? { actions: { primary: primaryAction } } : null)
    });
  }

  return errMessage;
};

export const extractFieldErrorParams = (data): Record<string, string> => {
  const parsedData = JSON.parse(data.errors);

  const convertedData = parsedData[0].params.map((param: { key: string; value: number }) => ({
    key: param.key,
    value: String(param.value)
  }));

  const record: Record<string, string> = {};
  convertedData.forEach((param) => {
    record[param.key] = param.value;
  });

  return record;
};
