import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { Account } from './account.types';

export const getAccount = () => RedisApiAxiosInstance.get(`${ApiPaths.ACCOUNTS}?current=true`);

export const updateAccount = (payload: Account) =>
  RedisApiAxiosInstance.put(`${ApiPaths.ACCOUNTS}/${payload.id}`, { account: payload });

export const getAccounts = () => RedisApiAxiosInstance.get(ApiPaths.ACCOUNTS);

export const setCurrentAccount = (accountId: number) =>
  RedisApiAxiosInstance.post(`${ApiPaths.ACCOUNTS}/setcurrent/${accountId}`);

export const createCloudApiKey = () =>
  RedisApiAxiosInstance.post(`${ApiPaths.ACCOUNTS}/cloud-api/cloudApiAccessKey`);

export const assignMarketplaceAccountId = (id: string) =>
  RedisApiAxiosInstance.post(`${ApiPaths.ACCOUNTS}/assign-marketplace`, { id });

export const setProduct = (type: ProductType) =>
  RedisApiAxiosInstance.post(`${ApiPaths.ACCOUNTS}/setproduct?type=${type}`);

export const assignMarketplaceAccountIds = (accountIds: number[]) =>
  RedisApiAxiosInstance.post(`${ApiPaths.ACCOUNTS}/assign-marketplace-multiple-accounts`, [
    ...accountIds
  ]);
