import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import './ui-components-styles.css';
import '@redislabsdev/redis-ui-styles/normalized-styles.css';
import '@redislabsdev/redis-ui-styles/fonts.css';
import './app.css';
import { I18nextProvider } from 'react-i18next';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import { routes } from 'utils/constants/routes';
import LogOut from 'screens/LogOut';
import CaptchaFrame from 'components/CaptchaFrame/CaptchaFrame';
import { TooltipProvider } from '@redislabsdev/redis-ui-components';
import { BotProtection } from 'components/BotProtection/BotProtection';
import FeatureFlagsProvider from 'components/FeatureFlagsProvider/FeatureFlagsProvider';
import setupLocatorUI from '@locator/runtime';
import Maintenance from 'screens/Maintenance/Maintenance';
import ThemesProvider from 'themes/components/ThemesProvider/ThemesProvider';
import MFEsProvider from 'components/MFEsProvider/MFEsProvider';
import { AppToaster } from './components/Toast/AppToaster';
import i18n from './locale/i18n';
import store, { sagaMiddleware } from './store';
import rootSaga from './store/saga.root';
import history from './hashHistory';
import { ResponsiveRem } from './utils/style/responsive.style';
import { queryClient } from './utils/initializeQueryClient';
import SentryErrorBoundary from './services/sentry/components/SentryErrorBoundary';
import AuthBroadcast from './services/broadcastChannels/AuthBroadcast/AuthBroadcast';
import InitializeOkta from './screens/AuthenticationWrappers/InitializeOkta';
import { CheckPendingTestConnection } from './screens/AuthenticationWrappers/CheckPendingTestConnection';
import SmAuthWrapper from './screens/AuthenticationWrappers/SmAuthWrapper';
import Routes from './screens/Routes';
import { LoadSystemParameters } from './screens/AuthenticationWrappers/LoadSystemParameters';
import { RedirectToSignUp } from './screens/RedirectToSignUp';
import { DialogIndicatorContextProvider } from './utils/helpers/dialogIndicatorContext';
import ParseTokens from './screens/AuthenticationWrappers/ParseTokens';
import { LoadCurrentUserAndPermissions } from './screens/AuthenticationWrappers/LoadCurrentUserAndPermissions';
import ValidateCookiesEnabled from './components/ValidateCookiesEnabled/ValidateCookiesEnabled';
import initMfesApiServices from './services/initMfesApiServices/initMfesApiServices';

if (process.env.NODE_ENV === 'development') {
  setupLocatorUI();
}

sagaMiddleware?.run(rootSaga);
initMfesApiServices();

function App() {
  AuthBroadcast.useChannel();

  return (
    <TooltipProvider>
      <CaptchaFrame />
      <I18nextProvider i18n={i18n}>
        <SentryErrorBoundary errorArea="main-app">
          <ValidateCookiesEnabled>
            <DialogIndicatorContextProvider>
              <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                  <BotProtection />
                  <ResponsiveRem />
                  <Router history={history}>
                    <Switch>
                      <Route exact path={routes.logout} component={LogOut} />
                      <Route exact path={routes.maintenance} component={Maintenance} />
                      <LoadSystemParameters>
                        <RedirectToSignUp>
                          <InitializeOkta>
                            <ParseTokens>
                              <LoadCurrentUserAndPermissions>
                                <CheckPendingTestConnection>
                                  <SmAuthWrapper>
                                    <FeatureFlagsProvider>
                                      <ThemesProvider>
                                        <MFEsProvider>
                                          <Routes />
                                        </MFEsProvider>
                                      </ThemesProvider>
                                    </FeatureFlagsProvider>
                                  </SmAuthWrapper>
                                </CheckPendingTestConnection>
                              </LoadCurrentUserAndPermissions>
                            </ParseTokens>
                          </InitializeOkta>
                        </RedirectToSignUp>
                      </LoadSystemParameters>
                    </Switch>
                  </Router>
                  <ReactQueryDevtools position="bottom-right" />
                </QueryClientProvider>
              </Provider>
            </DialogIndicatorContextProvider>
            <AppToaster />
          </ValidateCookiesEnabled>
        </SentryErrorBoundary>
      </I18nextProvider>
    </TooltipProvider>
  );
}

export default App;
