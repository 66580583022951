import { SubscriptionsAndDatabasesTheme } from 'themes/types/themeApp/components/subscriptionsAndDatabases.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: SubscriptionsAndDatabasesTheme = {
  subscriptions: {
    changeSubPlan: {
      changeSubPlan: {
        title: {
          color: themeUi.semantic.color.text.neutral800
        }
      },

      components: {
        subscriptionDetails: {
          subscriptionDetails: {
            backgroundColor: themeUi.semantic.color.background.neutral100
          },
          subID: {
            color: themeUi.semantic.color.text.neutral800
          },
          horizontalLine: {
            borderBottom: themeUi.semantic.color.border.neutral400
          },
          cloudVendorText: {
            color: themeUi.semantic.color.text.neutral800
          }
        }
      }
    },

    subscriptionDetails: {
      components: {
        connectivity: {
          psc: {
            components: {
              createConnectionForm: {
                createConnectionForm: {
                  creationForm: {
                    backgroundColor: themeUi.semantic.color.background.neutral100
                  },
                  creationFormTitle: {
                    color: themeUi.semantic.color.text.neutral800
                  },
                  stepCircle: {
                    activeBackgroundColor: themeUi.semantic.color.background.primary500,
                    normalBackgroundColor: themeUi.semantic.color.background.neutral400
                  },
                  stepCircleDivider: {
                    backgroundColor: themeUi.semantic.color.background.neutral400
                  },
                  divider: {
                    backgroundColor: themeUi.semantic.color.background.neutral400
                  }
                },

                components: {
                  addConnectionsStep: {
                    addConnectionTitle: {
                      color: themeUi.semantic.color.text.neutral800
                    },
                    addConnectionDescription: {
                      color: themeUi.semantic.color.text.neutral800
                    }
                  },

                  endpointDetailsStep: {
                    formBodyTitle: {
                      color: themeUi.semantic.color.text.neutral800
                    }
                  },

                  endpointScripTextArea: {
                    endpointScriptTextArea: {
                      scriptLabel: {
                        borderBottom: themeUi.semantic.color.border.primary600,
                        color: themeUi.semantic.color.text.primary600
                      }
                    }
                  }
                }
              },

              pscEmptyListFallback: {
                pscEmptyListFallback: {
                  description: {
                    color: themeUi.semantic.color.text.neutral700
                  }
                },

                components: {
                  disableStateInfo: {
                    disableStateInfo: {
                      backgroundColor: themeUi.semantic.color.background.primary100,
                      color: themeUi.semantic.color.text.notice700
                    }
                  }
                }
              },

              pscFailedScreen: {
                pscFailedTitle: {
                  color: themeUi.semantic.color.text.danger500,
                  fontSize: themeUi.core.font.fontSize.s18
                },
                pscFailedDescription: {
                  fontSize: themeUi.core.font.fontSize.s14
                },
                pscErrorContainer: {
                  backgroundColor: themeUi.semantic.color.text.neutral100
                }
              },

              pscTable: {
                pscTable: {
                  tableTitle: {
                    color: themeUi.semantic.color.text.neutral800
                  },
                  pscProjectName: {
                    color: themeUi.semantic.color.text.neutral600
                  }
                },

                pscTableCells: {
                  hostCell: {
                    components: {
                      connectDbDrawer: {
                        connectDescription: {
                          color: themeUi.semantic.color.text.neutral800,
                          fontSize: themeUi.core.font.fontSize.s16
                        },
                        pscEndpointHostAndPort: {
                          backgroundColor: themeUi.semantic.color.background.neutral300
                        },
                        dbPort: {
                          color: themeUi.semantic.color.text.primary500,
                          fontSize: themeUi.core.font.fontSize.s14
                        }
                      }
                    }
                  }
                }
              }
            }
          },

          tgw: {
            components: {
              tgwCard: {
                tgwCard: {
                  cardBodyValue: {
                    backgroundColor: themeUi.semantic.color.background.neutral300,
                    color: themeUi.semantic.color.text.neutral700,
                    fontFamily: themeUi.core.font.fontFamily.sourceCodeProRegular
                  },
                  cardBodyTitle: {
                    fontSize: themeUi.core.font.fontSize.s16,
                    color: themeUi.semantic.color.text.neutral800
                  },
                  cardBodyDescription: {
                    fontSize: themeUi.core.font.fontSize.s14,
                    color: themeUi.semantic.color.text.neutral700
                  },
                  divider: {
                    borderLeft: themeUi.semantic.color.border.neutral400
                  }
                },

                components: {
                  resourceSharesButton: {
                    missingPermissionsModal: {
                      accountName: {
                        color: themeUi.semantic.color.text.primary600
                      }
                    },

                    sharedInvitationsNumber: {
                      backgroundColor: themeUi.semantic.color.background.success500,
                      color: themeUi.semantic.color.text.neutral100,
                      fontSize: themeUi.core.font.fontSize.s10
                    }
                  },

                  tgwResourceDrawer: {
                    tgwResourceTable: {
                      tgwResourceTable: {
                        border: themeUi.semantic.color.border.neutral500
                      },

                      components: {
                        acceptRejectCell: {
                          acceptingStatusText: {
                            color: themeUi.semantic.color.text.neutral700
                          },
                          acceptedStatusText: {
                            color: themeUi.semantic.color.text.success500
                          },
                          rejectedStatusText: {
                            color: themeUi.semantic.color.text.danger500
                          }
                        }
                      }
                    }
                  }
                }
              },

              tgwCardHeader: {
                headerNumber: {
                  color: themeUi.semantic.color.text.neutral700,
                  backgroundColor: themeUi.semantic.color.background.informative100,
                  fontSize: themeUi.core.font.fontSize.s14,
                  fontFamily: themeUi.core.font.fontFamily.regular
                },
                headerTitle: {
                  color: themeUi.semantic.color.text.neutral800,
                  fontSize: themeUi.core.font.fontSize.s18,
                  fontFamily: themeUi.core.font.fontFamily.regular
                },
                headerDescription: {
                  color: themeUi.semantic.color.text.neutral700,
                  fontSize: themeUi.core.font.fontSize.s14
                }
              },

              tgwTable: {
                components: {
                  consumerCIDRsCell: {
                    components: {
                      tgwAttachmentDrawer: {
                        tgwAttachmentDrawer: {
                          attachmentTable: {
                            border: themeUi.semantic.color.border.neutral500
                          },
                          additionalDataTitle: {
                            color: themeUi.semantic.color.text.neutral800
                          },
                          additionalDataValue: {
                            color: themeUi.semantic.color.text.neutral700
                          },
                          divider: {
                            darkBorderBottom: themeUi.semantic.color.border.neutral500,
                            normalBorderBottom: themeUi.semantic.color.border.neutral400
                          },
                          routingRuleBanner: {
                            backgroundColor: themeUi.semantic.color.background.primary50
                          }
                        },

                        components: {
                          blackHoleCidrIndicator: {
                            blackholeWarningMessage: {
                              color: themeUi.semantic.color.text.neutral700,
                              fontSize: themeUi.core.font.fontSize.s12
                            },
                            indicatorIcon: {
                              color: themeUi.semantic.color.text.attention500
                            }
                          },

                          createTgwCidrs: {
                            consumerCidrsList: {
                              color: themeUi.semantic.color.text.neutral800
                            }
                          },
                          editTgwCidrs: {
                            consumerCidrsList: {
                              color: themeUi.semantic.color.text.neutral800
                            }
                          },
                          tgwCidrs: {
                            consumerCidrsTitle: {
                              color: themeUi.semantic.color.text.neutral800
                            }
                          },
                          viewTgwCidrs: {
                            consumerCidrsList: {
                              color: themeUi.semantic.color.text.neutral800
                            },
                            emptyCidrsListText: {
                              color: themeUi.semantic.color.text.neutral700
                            }
                          }
                        }
                      },

                      tgwCidrComponent: {
                        blackholeIndicator: {
                          blackHoleExistIndicator: {
                            tooltipTextParagraph: {
                              color: themeUi.semantic.color.text.neutral800
                            }
                          }
                        },

                        tgwCidrComponent: {
                          color: themeUi.semantic.color.text.neutral800
                        }
                      }
                    }
                  }
                }
              }
            }
          },

          vpcPeering: {
            components: {
              addVpcDialogs: {
                addVpcPeeringDrawerContent: {
                  addVpcPeeringDrawerContent: {
                    drawerForm: {
                      borderBottom: themeUi.semantic.color.border.neutral400
                    }
                  },

                  addVpcPeeringDrawerHeader: {
                    DrawerExplanation: {
                      color: themeUi.semantic.color.text.neutral700
                    }
                  },

                  components: {
                    awsPeeringContent: {
                      components: {
                        addAwsPeeringCidrs: {
                          vpcCidrsTitle: {
                            color: themeUi.semantic.color.text.neutral800
                          }
                        },

                        regionsDropdown: {
                          regionDisplayField: {
                            color: themeUi.semantic.color.text.neutral700
                          }
                        }
                      }
                    },

                    gcpPeeringContent: {
                      components: {
                        googleCloudCommand: {
                          components: {
                            commandDisplayAndCopy: {
                              title: {
                                color: themeUi.semantic.color.text.neutral700
                              },
                              commandContainer: {
                                border: themeUi.semantic.color.border.neutral500
                              },
                              commandText: {
                                color: themeUi.semantic.color.text.neutral800
                              }
                            }
                          }
                        }
                      }
                    },

                    redisCidrBox: {
                      redisCidrBox: {
                        backgroundColor: themeUi.semantic.color.background.primary50
                      }
                    }
                  }
                }
              }
            }
          }
        },

        customerManaged: {
          encryptionKeyCreateForm: {
            encryptionKeyCreateForm: {
              divider: {
                borderTop: themeUi.semantic.color.border.neutral600
              }
            },

            components: {
              grantAccessGCPCard: {
                dividerText: {
                  color: themeUi.semantic.color.border.neutral800
                },
                input: {
                  color: themeUi.semantic.color.border.neutral700
                },
                dividerVertical: {
                  borderRightColor: themeUi.semantic.color.border.neutral400
                },
                divider: {
                  borderTopColor: themeUi.semantic.color.border.neutral400
                }
              },

              stepCard: {
                card: {
                  border: themeUi.semantic.color.border.neutral500
                },
                stepCircle: {
                  color: themeUi.semantic.color.text.neutral700,
                  backgroundColor: themeUi.semantic.color.background.primary100
                }
              },
              providerResourcesCard: {
                title: {
                  color: themeUi.semantic.color.text.neutral800
                },
                countryCity: {
                  color: themeUi.semantic.color.text.neutral800
                }
              }
            }
          }
        },

        overview: {
          overview: {
            card: {
              backgroundColor: themeUi.semantic.color.background.neutral100,
              boxShadow: themeUi.core.shadow.shadow2
            },
            text: {
              color: themeUi.semantic.color.text.neutral800
            },
            header: {
              color: themeUi.semantic.color.text.neutral800
            },
            divider: {
              normalBorderTop: themeUi.semantic.color.border.neutral400,
              boldBorderTop: themeUi.semantic.color.border.neutral500
            },
            dividerVertical: {
              borderRight: themeUi.semantic.color.border.neutral400
            },
            spacer: {
              color: themeUi.semantic.color.text.neutral500
            }
          },

          components: {
            databasePlanCard: {
              divider: {
                backgroundColor: themeUi.semantic.color.background.neutral400
              }
            },

            maintenanceWindow: {
              explanationText: {
                color: themeUi.semantic.color.text.neutral800
              },
              title: {
                color: themeUi.semantic.color.text.neutral800
              },
              errorBanner: {
                backgroundColor: themeUi.semantic.color.background.attention100,
                borderColor: themeUi.semantic.color.border.attention200,
                color: themeUi.semantic.color.text.attention700,

                buttonBackgroundColor: themeUi.semantic.color.background.attention100,
                buttonBorderColor: themeUi.semantic.color.border.attention200,
                buttonColor: themeUi.semantic.color.text.attention700,

                buttonHoverBackgroundColor: themeUi.semantic.color.background.attention200,
                buttonHoverColor: themeUi.semantic.color.text.neutral700
              },
              divider: {
                borderBottomColor: themeUi.semantic.color.border.neutral400
              },

              manualSection: {
                recommendationStyle: {
                  satisfiedColor: themeUi.semantic.color.text.success600,
                  unsatisfiedColor: themeUi.semantic.color.text.neutral600
                },
                explanationTitle: {
                  color: themeUi.semantic.color.text.neutral800
                },
                addButton: {
                  color: themeUi.semantic.color.text.neutral800,
                  backgroundColor: themeUi.semantic.color.background.primary100,
                  hoverColor: themeUi.semantic.color.text.neutral800,
                  hoverBackgroundColor: themeUi.semantic.color.background.primary100
                }
              },

              readOnlyView: {
                tile: {
                  backgroundColor: themeUi.semantic.color.background.primary50,
                  color: themeUi.semantic.color.text.neutral800
                },
                label: {
                  color: themeUi.semantic.color.text.neutral700
                }
              },

              skipMaintenance: {
                remainingSkips: {
                  color: themeUi.semantic.color.text.neutral700
                },
                skipButton: {
                  backgroundColor: themeUi.semantic.color.background.neutral100
                }
              },

              timeFrame: {
                windowDescriptionContainer: {
                  color: themeUi.semantic.color.text.neutral800
                }
              }
            },

            pricingCard: {
              hourlyPriceValue: {
                borderTop: themeUi.semantic.color.border.neutral400
              }
            }
          }
        },

        payNowOrCancel: {
          PayNowContainer: {
            borderTop: themeUi.semantic.color.border.neutral600
          }
        },

        planCard: {
          metaContainer: {
            borderTop: themeUi.semantic.color.border.neutral400,
            borderBottom: themeUi.semantic.color.border.neutral400
          },

          metaItemText: {
            color: themeUi.semantic.color.text.neutral700
          },

          divider: {
            backgroundColor: themeUi.semantic.color.background.neutral400
          },

          components: {
            editSubscriptionName: {
              subscriptionName: {
                color: themeUi.semantic.color.text.neutral800
              }
            }
          }
        },

        regions: {
          components: {
            addRegion: {
              components: {
                addRegionDialog: {
                  components: {
                    steps: {
                      throughput: {
                        table: {
                          border: themeUi.semantic.color.border.neutral400
                        }
                      }
                    }
                  }
                }
              }
            },

            addRegionCard: {
              addRegionCard: {
                backgroundColor: themeUi.semantic.color.background.neutral100,
                color: themeUi.semantic.color.text.neutral500,
                hoverBackgroundColor: themeUi.semantic.color.background.primary300
              }
            },

            regionAccessTooltip: {
              tooltipHeader: {
                backgroundColor: themeUi.semantic.color.background.attention100
              },
              title: {
                color: themeUi.semantic.color.text.neutral800
              },
              strongText: {
                color: themeUi.semantic.color.text.neutral800
              },
              bodyText: {
                color: themeUi.semantic.color.text.neutral800
              }
            },

            shared: {
              steps: {
                stepTab: {
                  border: themeUi.semantic.color.border.primary100
                }
              }
            },

            submitDialog: {
              components: {
                affectedRegions: {
                  listContainer: {
                    borderColor: themeUi.semantic.color.border.neutral400
                  },
                  listItem: {
                    borderTopColor: themeUi.semantic.color.border.neutral400
                  },
                  actionLabel: {
                    color: themeUi.semantic.color.text.danger500
                  }
                }
              }
            }
          }
        },

        subscriptionDetailsHeader: {
          subscriptionMetadataContainer: {
            color: themeUi.semantic.color.text.neutral800
          },
          subscriptionName: {
            color: themeUi.semantic.color.text.neutral800
          },
          link: {
            color: themeUi.semantic.color.text.neutral700
          },

          components: {
            subscriptionMetadata: {
              subscriptionMetadata: {
                color: themeUi.semantic.color.text.neutral700
              }
            },

            advancedTooltipErrorContent: {
              subDetailsTooltipErrorText: {
                color: themeUi.semantic.color.text.danger500
              }
            },

            changePlanTooltip: {
              closeIcon: {
                color: themeUi.semantic.color.text.neutral700
              },
              tooltipTitle: {
                color: themeUi.semantic.color.text.neutral800
              },
              text: {
                color: themeUi.semantic.color.text.neutral800
              }
            },

            createDbButton: {
              components: {
                tooltipContent: {
                  tooltipTextExplanation: {
                    backgroundColor: themeUi.semantic.color.background.primary50
                  },
                  tooltipTextAction: {
                    borderTop: themeUi.semantic.color.border.neutral400
                  }
                }
              }
            },

            optInRequestButton: {
              optedInBadge: {
                backgroundColor: themeUi.semantic.color.background.success100,
                color: themeUi.semantic.color.text.success600,
                border: themeUi.semantic.color.border.success200,
                fill: themeUi.semantic.color.icon.success600
              },

              optInButton: {
                backgroundColor: themeUi.semantic.color.icon.neutral100
              }
            },

            redisVersionUpgradeRequestButton: {
              upgradeRequestedBadge: {
                backgroundColor: themeUi.semantic.color.background.neutral100
              },
              versionSelectOption: {
                color: themeUi.semantic.color.text.neutral600
              },
              modalExplanation: {
                color: themeUi.semantic.color.text.neutral700
              },
              modalReleaseNotes: {
                color: themeUi.semantic.color.text.neutral800
              }
            }
          }
        }
      }
    },

    components: {
      databaseViewHeader: {
        components: {
          dbsSelectDropDown: {
            subTitle: {
              fontFamily: themeUi.core.font.fontFamily.regular
            }
          }
        }
      },

      bdbMetrics: {
        metricsInfo: {
          color: themeUi.semantic.color.text.neutral700
        }
      },

      slowLogs: {
        readMoreContainer: {
          color: themeUi.semantic.color.text.neutral700,
          hasUrlColor: themeUi.semantic.color.text.primary600
        }
      }
    }
  },

  components: {
    dbIndicators: {
      components: {
        dbProcesses: {
          labeledProcess: {
            backgroundColor: themeUi.semantic.color.background.neutral100
          }
        }
      }
    },

    dbFormComponents: {
      allowList: {
        components: {
          allowListItems: {
            borderColor: themeUi.semantic.color.border.neutral400
          },
          allowListItem: {
            allowListItemContainer: {
              borderTopColor: themeUi.semantic.color.border.neutral300
            }
          }
        }
      },

      collapseAllButton: {
        collapseAllText: {
          color: themeUi.semantic.color.text.primary600
        }
      },

      configurationSectionItem: {
        description: {
          color: themeUi.semantic.color.text.neutral600
        }
      },

      dangerZoneItem: {
        label: {
          color: themeUi.semantic.color.text.neutral800
        }
      },

      defaultUser: {
        components: {
          defaultUsernameIndication: {
            textContainer: {
              color: themeUi.semantic.color.text.neutral700
            },
            defaultText: {
              color: themeUi.semantic.color.text.notice500
            }
          }
        }
      },

      hashingPolicy: {
        hashingPolicyLabelAndValue: {
          components: {
            hashingPolicyLabel: {
              label: {
                color: themeUi.semantic.color.text.neutral800
              },
              additionalInfo: {
                color: themeUi.semantic.color.text.neutral600
              }
            },
            hashingPolicyValue: {
              policyValue: {
                color: themeUi.semantic.color.text.neutral800
              }
            }
          }
        },

        hashingPolicy: {
          policyValue: {
            color: themeUi.semantic.color.text.neutral800
          }
        },

        regexRulesEdit: {
          componentContainer: {
            color: themeUi.semantic.color.text.success500
          },

          components: {
            regexExample: {
              header: {
                color: themeUi.semantic.color.text.neutral800
              },
              column: {
                color: themeUi.semantic.color.text.primary500
              },
              descriptionText: {
                color: themeUi.semantic.color.text.neutral600
              },
              dividerVertical: {
                borderLeft: themeUi.semantic.color.border.neutral400
              }
            },
            regexQuickReference: {
              header: {
                color: themeUi.semantic.color.text.neutral800
              },
              key: {
                color: themeUi.semantic.color.text.primary500
              },
              value: {
                color: themeUi.semantic.color.text.neutral600
              }
            },
            tooltipsContainer: {
              tooltipsContainer: {
                color: themeUi.semantic.color.text.primary600
              }
            }
          }
        }
      },

      alerts: {
        alertText: {
          color: themeUi.semantic.color.text.neutral800
        },
        alertInputText: {
          disabledColor: themeUi.semantic.color.text.neutral500,
          editableColor: themeUi.semantic.color.text.neutral700,
          notActiveColor: themeUi.semantic.color.text.neutral500,
          color: themeUi.semantic.color.text.neutral800
        }
      },

      importDbDialog: {
        components: {
          importIndicator: {
            importText: {
              color: themeUi.semantic.color.text.neutral600
            },
            errorText: {
              color: themeUi.semantic.color.text.danger500
            }
          },

          redisSource: {
            defaultUserDescription: {
              color: themeUi.semantic.color.text.neutral600
            },

            redisSourcePathInput: {
              pathPatternText: {
                color: themeUi.semantic.color.text.neutral600
              }
            }
          }
        }
      },

      ossCluster: {
        statusText: {
          color: themeUi.semantic.color.text.neutral800
        }
      },

      proxyPolicyOverride: {
        description: {
          color: themeUi.semantic.color.text.neutral600
        },
        compressionValue: {
          color: themeUi.semantic.color.text.neutral800
        }
      },

      quickActionCardBox: {
        cardBox: {
          borderRadius: themeUi.components.boxSelectionGroup.item.borderRadius,
          borderWidth: themeUi.components.boxSelectionGroup.item.borderWidth,
          borderStyle: themeUi.components.boxSelectionGroup.item.borderStyle,
          borderColor: themeUi.semantic.color.border.neutral400
        }
      },

      remoteBackup: {
        lastUpdateTime: {
          color: themeUi.semantic.color.text.primary400
        },

        components: {
          destinationSection: {
            components: {
              defaultBackupDestination: {
                components: {
                  downloadLink: {
                    downloadLink: {
                      backgroundColor: themeUi.semantic.color.background.primary100,
                      hoverBackgroundColor: themeUi.semantic.color.background.primary200
                    }
                  }
                }
              },

              providerBackupDestination: {
                pathPatternText: {
                  color: themeUi.semantic.color.text.neutral600
                }
              }
            }
          },

          explanationSection: {
            container: {
              color: themeUi.semantic.color.text.primary400
            }
          },

          storageTypeSection: {
            storageTypeItem: {
              color: themeUi.semantic.color.text.neutral800
            }
          }
        }
      },

      searchScalingFactor: {
        divider: {
          backgroundColor: themeUi.semantic.color.text.neutral400
        },

        components: {
          requestHigherFactor: {
            textButton: {
              borderTop: themeUi.semantic.color.text.neutral400
            }
          }
        }
      },

      supportedProtocol: {
        fieldName: {
          color: themeUi.semantic.color.text.neutral800
        },

        components: {
          resp3NewBadge: {
            resp3TooltipContent: {
              color: themeUi.semantic.color.text.neutral800
            }
          },

          supportedProtocolPopover: {
            popoverTitle: {
              color: themeUi.semantic.color.text.neutral800,
              fontSize: themeUi.core.font.fontSize.s16
            }
          },

          wrappers: {
            defaultRespWrapper: {
              fieldName: {
                color: themeUi.semantic.color.text.neutral800
              }
            }
          }
        }
      },

      transportLayerSecurity: {
        components: {
          certificateDetails: {
            certificateDetailsContainer: {
              borderTopColor: themeUi.semantic.color.border.neutral400
            },
            infoWithPipe: {
              borderRightColor: themeUi.semantic.color.border.neutral400
            },
            styledTable: {
              borderColor: themeUi.semantic.color.border.neutral400
            }
          },
          tlsClientAdd: {
            completeProcessInfoMessage: {
              borderTopColor: themeUi.semantic.color.border.neutral400,
              color: themeUi.semantic.color.text.neutral800
            },
            link: {
              color: themeUi.semantic.color.text.primary500,
              hoverColor: themeUi.semantic.color.text.primary600
            },
            styledTable: {
              borderColor: themeUi.semantic.color.border.neutral400
            }
          },
          tlsClientView: {
            clientTLSIndicationContainer: {
              backgroundColor: themeUi.semantic.color.background.neutral500
            },
            styledTable: {
              borderColor: themeUi.semantic.color.border.neutral400
            }
          }
        }
      },

      viewModules: {
        label: {
          color: themeUi.semantic.color.text.neutral800
        }
      }
    }
  }
};

export default theme;
