import { useWindowDimensions } from 'hooks';
import { useState, useEffect, useRef } from 'react';

const useIsTextOverflows = () => {
  const { screenWidth } = useWindowDimensions();
  const [isTextOverFlows, setIsTextOverFlows] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cellRef?.current) {
      if (
        cellRef.current.scrollHeight > cellRef.current.offsetHeight ||
        cellRef.current.scrollWidth > cellRef.current.offsetWidth
      ) {
        setIsTextOverFlows(true);
      } else if (isTextOverFlows) {
        setIsTextOverFlows(false);
      }
    }
  }, [cellRef, screenWidth, isTextOverFlows]);

  return { isTextOverFlows, cellRef };
};

export default useIsTextOverflows;
