import { FormField, Select } from '@redislabsdev/redis-ui-components';
import { setDataScrollAnchor } from '../../../utils/dataScrollAnchorUtils';
import * as S from './InputFields.style';

interface IProps {
  width: string;
  name?: string;
  label: string;
  error?: string;
  states: State[];
  selectedState: State;
  onStateSelect: (state: State) => void;
  info?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
}

const StateSelectInput = ({
  name,
  label,
  width,
  states,
  onStateSelect,
  selectedState,
  error,
  info = null,
  disabled = false,
  required = false
}: IProps) => {
  return (
    <S.FormFieldCompose $width={width} {...(name && setDataScrollAnchor(name))}>
      <FormField.Label
        required={required}
        data-testid="label--province-name"
        label={
          <S.Flex>
            {label}
            {info}
          </S.Flex>
        }
      />
      <span>
        <Select
          disabled={disabled}
          error={error}
          value={selectedState?.name}
          onChange={(value) => {
            const selectedItem = states.find((state) => state.name === value);
            selectedItem && onStateSelect(selectedItem);
          }}
          options={states.map((item: State) => ({
            item,
            value: item.name,
            label: item.name
          }))}
          data-testid="selectbox--province-name"
          placeholder=""
          searchable
          maxVisibleItems={6}
        />
      </span>
    </S.FormFieldCompose>
  );
};

export default StateSelectInput;
