import styled from 'styled-components/macro';

export const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 1rem;
  }
  width: 100%;
`;

export const EllipseIconWrapper = styled.div`
  width: 75%;
`;
