import styled from 'styled-components/macro';

export const ReadMoreContainer = styled.a`
  display: flex;
  align-items: center;
  &:link,
  &:visited {
    text-decoration: none;
  }

  color: ${({ theme }) => theme.appComponents.components.readMore.color};

  && :hover {
    color: ${({ theme }) => theme.appComponents.components.readMore.color};
  }
`;

export const ReadMore = styled.span`
  margin-left: 5px;
`;
