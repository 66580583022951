import { DownloadCenterTheme } from 'themes/types/themeApp/components/downloadCenter.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: DownloadCenterTheme = {
  downloadCenter: {
    linksContainer: {
      backgroundColor: themeUi.semantic.color.background.neutral100
    }
  },

  components: {
    downloadCard: {
      container: {
        rlecBackgroundColor: themeUi.semantic.color.background.primary600,
        notRlecBackgroundColor: themeUi.semantic.color.background.primary500,
        color: themeUi.semantic.color.text.neutral100
      },
      selectTrigger: {
        borderColor: themeUi.semantic.color.border.primary300,
        color: themeUi.semantic.color.text.neutral100,
        fill: themeUi.semantic.color.icon.neutral100
      },
      styledButton: {
        color: themeUi.semantic.color.text.neutral100
      }
    },

    links: {
      color: themeUi.semantic.color.text.neutral800
    }
  }
};

export default theme;
