import { MFEProvider } from '@redislabsdev/cloud-ui-mfe-context';
import { ThemeSwitchContext } from 'components/ThemeSwitch/context/ThemeSwitch.context';
import { ReactNode, useContext } from 'react';

const MFEsProvider = ({ children }: { children: ReactNode }) => {
  const { themeName } = useContext(ThemeSwitchContext);

  return <MFEProvider value={{ themeName }}>{children}</MFEProvider>;
};

export default MFEsProvider;
