import { initialThemeName, themes } from 'themes/hooks/useTheme';
import { ThemeName } from 'themes/hooks/useTheme.types';

export const STORED_THEME_NAME = 'theme';

export const getStoredThemeName = () => {
  const value = localStorage.getItem(STORED_THEME_NAME);

  if (themes[value]) {
    return value as ThemeName;
  }

  localStorage.setItem(STORED_THEME_NAME, initialThemeName);

  return initialThemeName;
};

export const storeThemeName = (themeName: ThemeName) => {
  localStorage.setItem(STORED_THEME_NAME, themeName);
};
