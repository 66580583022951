import { ConnectionWizardTheme } from 'themes/types/themeApp/components/connectionWizard.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: ConnectionWizardTheme = {
  components: {
    connectionWizard: {
      codeSnippetBox: {
        border: themeUi.semantic.color.border.neutral500,
        color: themeUi.semantic.color.text.neutral800
      },
      codeSnippetBoxContent: {
        fontFamily: themeUi.core.font.fontFamily.sourceCodeProRegular
      },
      highlightedPlaceholder: {
        color: themeUi.semantic.color.text.notice500
      },
      highlightedPlaceholderSecondary: {
        color: themeUi.semantic.color.text.neutral700
      },
      selectTrigger: {
        backgroundColor: themeUi.semantic.color.background.primary100
      },
      selectTriggerValue: {
        color: themeUi.semantic.color.text.primary600
      },
      label: {
        color: themeUi.semantic.color.text.neutral800
      }
    },

    connectionWizardCli: {
      separator: {
        backgroundColor: themeUi.semantic.color.background.neutral400
      }
    },

    connectionWizardClient: {
      separator: {
        backgroundColor: themeUi.semantic.color.background.neutral400
      }
    },

    copySnippet: {
      label: {
        color: themeUi.semantic.color.text.success500
      }
    },

    redisInsightConnection: {
      explanationHeader: {
        color: themeUi.semantic.color.text.neutral800
      },
      actionButtons: {
        borderTop: themeUi.semantic.color.border.neutral400
      }
    }
  }
};

export default theme;
