/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from 'react';
import { PAGINATION_SIZE } from '../components/TablePagination/TablePagination.style';
import { TABLE_ROW_HEIGHT as HEADER_SIZE } from '../Table.style';

const MARGIN = 25;

const useTableBodyHeight = (
  ref: React.MutableRefObject<HTMLElement | null>,
  noPagination?: boolean
): number => {
  const [tableBodyHeight, setTableBodyHeight] = useState(0);
  const OFFSET = HEADER_SIZE + (noPagination ? 0 : PAGINATION_SIZE) + MARGIN;

  const handleResize = () => {
    if (ref.current) {
      const { y } = ref.current.getBoundingClientRect();
      setTableBodyHeight(window.innerHeight - y - OFFSET);
    }
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return tableBodyHeight;
};

export default useTableBodyHeight;
