import { UsageReportTheme } from 'themes/types/themeApp/components/usageReport.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: UsageReportTheme = {
  sectionTitle: {
    color: themeUi.semantic.color.text.neutral800,
    spanColor: themeUi.semantic.color.text.neutral700,
    beforeColor: themeUi.semantic.color.text.neutral800
  },
  label: {
    color: themeUi.semantic.color.text.neutral800
  }
};

export default theme;
