import useTheme from 'themes/hooks/useTheme';
import { ThemeProvider } from 'styled-components/macro';
import ThemeSwitchProvider from 'components/ThemeSwitch/context/ThemeSwitch.context';
import { ThemeSwitchProviderProps } from './ThemesProvider.types';

const ThemesProvider = ({ children }: ThemeSwitchProviderProps) => {
  const { theme, themeName, setThemeName } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <ThemeSwitchProvider themeName={themeName} setThemeName={setThemeName}>
        {children}
      </ThemeSwitchProvider>
    </ThemeProvider>
  );
};

export default ThemesProvider;
