import { FC } from 'react';
import { IconSizeType, NewTabIcon } from '@redislabsdev/redis-ui-icons';
import { usePrefixedTranslation } from '../../hooks';
import * as S from './ReadMore.style';

interface ReadMoreProps {
  readMoreLink: string;
  dataTestId?: string;
  className?: string;
  withText?: boolean;
  size?: IconSizeType;
}

const ReadMore: FC<ReadMoreProps> = ({
  readMoreLink,
  dataTestId,
  className,
  children,
  withText = true,
  size = 'M'
}) => {
  const { prefixedT } = usePrefixedTranslation('labels');

  return (
    <S.ReadMoreContainer
      target="_blank"
      rel="noreferrer"
      {...{ className }}
      href={readMoreLink}
      data-testid={dataTestId || 'link--read-more'}
    >
      <NewTabIcon size={size} customColor="currentColor" />
      {withText && <S.ReadMore>{children ?? prefixedT('readMore')}</S.ReadMore>}
    </S.ReadMoreContainer>
  );
};

export default ReadMore;
