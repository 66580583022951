import styled from 'styled-components/macro';
import { SearchInput as SearchInputComponent } from '@redislabsdev/redis-ui-components';

export const ContentContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24.5rem;
`;

export const SearchInput = styled(SearchInputComponent)`
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 0.6rem;
`;

export const Divider = styled.hr`
  width: 27.7rem;
  height: 1px;
  background-color: ${({ theme }) =>
    theme.appComponents.shared.table.components.columnSearchFilter.divider.backgroundColor};
  margin: 1.6rem -1.6rem 0;
`;
