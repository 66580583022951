import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  margin-bottom: 0.2rem;
  display: flex;

  & > *:nth-child(2) {
    line-height: 0;
  }
`;

export const InputLabel = styled.label`
  margin-right: 0.5rem;
  white-space: nowrap;
`;

export const ReadMoreLink = styled.a<{ withMargin?: boolean }>`
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-left: auto;
    `}
`;

export const Optional = styled.span`
  font-size: 1.2rem;
  color: ${({ theme }) =>
    theme.appComponents.components.formControls.inputLabelWithInfo.optional.color};
  margin-left: 0.5rem;
`;
