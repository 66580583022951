import { MarketplaceTheme } from 'themes/types/themeApp/components/marketplace.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: MarketplaceTheme = {
  accessManagement: {
    apiKeys: {
      integrationToolsLinks: {
        color: themeUi.semantic.color.text.neutral800
      },
      divider: {
        backgroundColor: themeUi.semantic.color.background.neutral400
      }
    }
  },

  accountMapping: {
    accountMapping: {
      balloonsImage: {
        backgroundColor: themeUi.semantic.color.background.primary700,
        background: themeUi.semantic.color.background.primary700
      },
      logoImage: {
        backgroundColor: themeUi.semantic.color.background.primary700,
        background: themeUi.semantic.color.background.primary700
      },
      heading: {
        color: themeUi.semantic.color.text.neutral800
      },
      subheading: {
        disabledColor: themeUi.semantic.color.text.neutral500,
        normalColor: themeUi.semantic.color.text.neutral700
      },
      divider: {
        backgroundColor: themeUi.semantic.color.background.neutral400
      },
      noEligibleAccountsSubheading: {
        color: themeUi.semantic.color.text.neutral800
      },
      link: {
        color: themeUi.semantic.color.text.primary500,
        hoverColor: themeUi.semantic.color.text.primary600
      }
    },

    accountsRadio: {
      accountsRadioGroup: {
        accountName: {
          color: themeUi.semantic.color.text.neutral800,
          disabledColor: themeUi.semantic.color.text.neutral500
        }
      }
    },

    mappingConsent: {
      title: {
        color: themeUi.semantic.color.text.primary700
      }
    },

    mappingFooter: {
      setupLaterModalSubheading: {
        color: themeUi.semantic.color.text.neutral800
      }
    }
  },

  freeTrial: {
    imageContainer: {
      backgroundColor: themeUi.semantic.color.background.primary700
    },
    heading: {
      color: themeUi.semantic.color.text.neutral800
    },
    subheading: {
      color: themeUi.semantic.color.text.neutral800
    },
    details: {
      color: themeUi.semantic.color.text.neutral700
    }
  }
};

export default theme;
