import { SubscriptionSideBarTheme } from 'themes/types/themeApp/components/subscriptionSideBar.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: SubscriptionSideBarTheme = {
  cardTable: {
    wrapper: {
      backgroundColor: themeUi.semantic.color.background.neutral100,
      borderRight: `1px solid ${themeUi.semantic.color.border.primary100}`
    },
    cardsContainer: {
      padding: themeUi.core.space.space100,
      gap: themeUi.core.space.space100
    },

    card: {
      variants: {
        normal: {
          backgroundColor: themeUi.semantic.color.background.neutral100,
          borderTopColor: themeUi.semantic.color.border.neutral600,
          borderRightColor: themeUi.semantic.color.border.neutral600,
          borderBottomColor: themeUi.semantic.color.border.neutral600,
          borderLeftColor: themeUi.semantic.color.border.neutral600,
          leftBarIndicatorBackgroundColor: 'transparent',
          borderRadius: '0.4rem'
        },
        selected: {
          backgroundColor: themeUi.semantic.color.background.primary200,
          borderTopColor: themeUi.semantic.color.border.primary300,
          borderRightColor: themeUi.semantic.color.border.primary300,
          borderBottomColor: themeUi.semantic.color.border.primary300,
          borderLeftColor: themeUi.semantic.color.border.primary300,
          leftBarIndicatorBackgroundColor: 'transparent',
          borderRadius: '0.4rem'
        }
      },
      components: {
        cardMiddle: {
          regionText: {
            color: themeUi.semantic.color.text.neutral800
          },
          subscriptionName: {
            selectedColor: themeUi.semantic.color.text.primary600,
            normalColor: themeUi.semantic.color.text.neutral800
          }
        },
        cardHeader: {
          headerText: {
            color: themeUi.semantic.color.text.neutral700
          }
        },
        cardFooter: {
          databaseDetails: {
            bolderRightColor: themeUi.semantic.color.border.neutral500
          },
          analyticText: {
            normalColor: themeUi.semantic.color.text.neutral700,
            selectedColor: themeUi.semantic.color.text.primary500
          }
        }
      }
    },

    components: {
      cardTableHeader: {
        container: {
          backgroundColor: themeUi.semantic.color.background.neutral100,
          borderBottom: themeUi.semantic.color.border.neutral400
        }
      }
    },

    sort: {
      sortDropDown: {
        container: {
          backgroundColor: themeUi.semantic.color.background.neutral100
        },
        category: {
          backgroundColor: themeUi.semantic.color.background.neutral400
        }
      }
    },

    filter: {
      filterDropDown: {
        body: {
          backgroundColor: themeUi.semantic.color.background.neutral100
        },
        filterContainer: {
          borderLeft: themeUi.semantic.color.border.neutral400,
          borderRight: themeUi.semantic.color.border.neutral400
        }
      },
      filterBy: {
        categoryOptions: {
          color: themeUi.semantic.color.text.neutral800
        },
        flashLabel: {
          color: themeUi.semantic.color.text.neutral700,
          colorHover: themeUi.semantic.color.text.neutral700
        }
      }
    }
  }
};

export default theme;
