import styled, { css } from 'styled-components/macro';

export const Cell = styled.div<{ $rows: number }>`
  ${({ $rows }) =>
    $rows > 1
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: ${$rows};
          -webkit-box-orient: vertical;
        `
      : css`
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
  overflow: hidden;
`;
