import { DatabaseTableTheme } from 'themes/types/themeApp/components/databaseTable.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: DatabaseTableTheme = {
  databaseTableCell: {
    capabilitiesCell: {
      components: {
        redisStackView: {
          text: {
            color: themeUi.semantic.color.text.neutral700
          },
          nextStepsText: {
            color: themeUi.semantic.color.text.neutral700
          },
          description: {
            color: themeUi.semantic.color.text.neutral800
          },
          moduleName: {
            color: themeUi.semantic.color.text.neutral700
          },
          tooltipContainerWrapper: {
            backgroundColor: themeUi.semantic.color.text.informative50
          },
          tooltipActionContainer: {
            backgroundColor: themeUi.semantic.color.text.neutral100
          },
          tooltipTitle: {
            color: themeUi.semantic.color.text.neutral700
          },
          explanationText: {
            color: themeUi.semantic.color.text.neutral800
          },
          connectText: {
            color: themeUi.semantic.color.text.neutral800
          },
          connectButton: {
            backgroundColor: themeUi.semantic.color.text.primary600
          }
        }
      }
    },

    bdbEndpointCell: {
      components: {
        firstDatabaseCongratulations: {
          closeIcon: {
            color: themeUi.semantic.color.icon.neutral700
          },
          explanationText: {
            color: themeUi.semantic.color.text.neutral800
          }
        }
      }
    },

    bdbStatusCell: {
      networkMemoryUsageAlert: {
        closeIcon: {
          color: themeUi.semantic.color.icon.neutral700
        }
      }
    },

    crdbOptionsCell: {
      components: {
        crdbOptionBadgeTooltipContent: {
          regionItem: {
            backgroundColor: themeUi.semantic.color.background.neutral300
          }
        }
      }
    },

    crdbThroughputTooltip: {
      components: {
        throughputTooltipContent: {
          container: {
            color: themeUi.semantic.color.text.neutral700
          },
          title: {
            color: themeUi.semantic.color.text.neutral800
          },
          throughputText: {
            color: themeUi.semantic.color.text.neutral800
          },
          tfootText: {
            color: themeUi.semantic.color.text.neutral800
          }
        }
      }
    },

    dbActionsMenuCell: {
      components: {
        dbActionsMenu: {
          iconButtonTrigger: {
            backgroundColor: themeUi.semantic.color.background.primary100
          },
          menuContentItem: {
            color: themeUi.semantic.color.text.neutral800,
            hoverColor: themeUi.semantic.color.text.neutral800
          }
        }
      }
    }
  }
};

export default theme;
