import React from 'react';
import { Button, Popover } from '@redislabsdev/redis-ui-components';
import * as S from './ColumnSearchFilter.style';
import CellFilter from '../CellFilter/CellFilter';

const ColumnSearchFilter = ({ column: { filterValue, setFilter } }) => {
  const [inputValue, setInputValue] = React.useState(filterValue);

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setInputValue(filterValue);
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  return (
    <CellFilter isFilterActive={!!filterValue} onVisibilityChange={handleVisibilityChange}>
      {(handleCloseOpen) => {
        const handleApply = () => {
          setFilter(inputValue);
          handleCloseOpen();
        };

        return (
          <S.ContentContainer
            onSubmit={(e) => {
              e.preventDefault();
              handleApply();
            }}
          >
            <S.SearchInput
              autoFocus
              value={inputValue || ''}
              onChange={handleInputChange}
              data-testid="search-input"
              type="text"
            />
            <S.Divider />
            <Popover.Card.Footer.Compose>
              <S.ButtonsContainer>
                <Button variant="secondary-ghost" onClick={handleCloseOpen}>
                  Cancel
                </Button>
                <Button onClick={handleApply}>Apply</Button>
              </S.ButtonsContainer>
            </Popover.Card.Footer.Compose>
          </S.ContentContainer>
        );
      }}
    </CellFilter>
  );
};

export default ColumnSearchFilter;
