import { ThemeName } from 'themes/hooks/useTheme.types';

export const applyTheme = (themeName: ThemeName) => {
  document.body.classList.add('theme-transition');
  document.documentElement.setAttribute('theme', themeName);
  window.sessionStorage.setItem('theme', themeName);

  setTimeout(() => {
    document.body.classList.remove('theme-transition');
  }, 300);
};
