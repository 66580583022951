import { ApiPaths } from 'services/api/apiPaths';
import { createResourceApi } from '../../createResourceApi';
import { PaymentInfoPostPayload } from '../../../../components/AccountAddressDrawer/AccountAddressDrawer.types';
import { IPaymentInfo } from '../../../../store/paymentMethods/paymentMethods.types';

export const paymentsInfosApi = createResourceApi(ApiPaths.PAYMENT_INFOS, (rest) => ({
  getOne: (paymentInfoId: number) =>
    rest.get<{ paymentInfo: IPaymentInfo }>({ path: `${paymentInfoId}` }),
  getCountriesData: () => rest.get<CountriesData>({ path: 'actions/get-countries' }),
  validateVatId: (vatID: string) =>
    rest.post<{ vatID: string }>({
      path: 'actions/validate-vatid',
      data: vatID
    }),
  getThreeDsToken: () => rest.get<{ threeDSToken: string }>({ path: 'actions/three-ds-token' }),
  create: (body: PaymentInfoPostPayload) =>
    rest.post<{ paymentInfo: IPaymentInfo }>({ data: body }),
  update: ({ pmID, body }: { pmID: number; body: PaymentInfoPostPayload }) =>
    rest.put<{ paymentInfo: IPaymentInfo }>({ path: `${pmID}`, data: body }),
  getAllowedMarketplaces: () => rest.get<MarketplaceInfo[]>({ path: 'allowed-marketplaces' }),
  getCurrentMarketplace: () => rest.get<MarketplaceInfo>({ path: 'current-marketplace' }),
  updateSubscriptionPaymentInfoToMarketplace: (subscriptionIds: number[]) =>
    rest.post({
      path: 'actions/update-subs-payment-info-to-marketplace',
      data: [...subscriptionIds]
    }),
  deleteMarketplaceForCurrentAccount: () => rest.delete({ path: 'actions/delete-marketplace' }),
  addMarketplaceForAccount: (marketplaceInfo: MarketplaceInfo) =>
    rest.put({ path: 'actions/add-marketplace', data: marketplaceInfo })
}));
