import styled, { css } from 'styled-components/macro';
import { IFontSize } from '../../types/styled';
import imageSubscription from './assets/subscription.svg';
import imageDatabase from './assets/database.svg';
import imageEmptySubscriptions from './assets/subscription.png';
import imageEmptyDatabases from './assets/database.png';

export type Logo = 'subscription' | 'database';

const logos: { [K in Logo]: string } = {
  subscription: imageSubscription,
  database: imageDatabase
};

const emptyLogos: { [K in Logo]: string } = {
  subscription: imageEmptySubscriptions,
  database: imageEmptyDatabases
};

export const Container = styled.div<{ marginTop: string }>`
  margin-top: ${({ marginTop }) => marginTop};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;
`;

export const Label = styled.p<{ fontSize: IFontSize; bold: boolean }>`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.appComponents.components.emptyListsFallback.label.color};
  font-size: ${({ fontSize, theme }) =>
    theme.appComponents.components.emptyListsFallback.label.fontSize[fontSize]};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;

export const Description = styled.p<{ fontSize: IFontSize }>`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.appComponents.components.emptyListsFallback.description.color};
  font-size: ${({ fontSize, theme }) =>
    theme.appComponents.components.emptyListsFallback.description.fontSize[fontSize]};
  margin-top: 0.8rem;
`;

export const LogoIcon = styled.div<{ logo: Logo }>`
  width: ${({ logo }) => (logo === 'subscription' ? '27.8rem' : '14rem')};
  height: ${({ logo }) => (logo === 'subscription' ? '17rem' : '22.5rem')};
  background-image: url(${({ logo }) => logos[logo]});
  background-size: contain;
  background-position: 50%;
  margin-bottom: 2.4rem;
  position: absolute;
  bottom: 100%;

  ${({ theme, logo }) =>
    ['light', 'dark'].includes(theme.name) &&
    css`
      width: 22rem;
      height: 22rem;
      background-image: url(${emptyLogos[logo]});
    `}
`;

export const ButtonContainer = styled.div`
  margin-top: 2.4rem;
`;
