import { css } from 'styled-components/macro';
import { MainTheme } from 'themes/types/themeApp/components/main.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: MainTheme = {
  mainContainer: {
    props: css``
  },

  bodyContainer: {
    bodyBackgroundColor: themeUi.semantic.color.background.neutral300,
    color: themeUi.semantic.color.icon.neutral100
  },

  hooks: {
    useInitializedStripe: {
      stripeElementsOptions: {
        colorText: themeUi.semantic.color.text.neutral800,
        colorTextPlaceholder: themeUi.semantic.color.text.neutral600,
        borderColor: themeUi.semantic.color.text.neutral500,
        fontFamily: themeUi.core.font.fontFamily.regular
      }
    }
  }
};

export default theme;
