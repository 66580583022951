import { ViewDbTheme } from 'themes/types/themeApp/components/viewDb.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: ViewDbTheme = {
  configuration: {
    components: {
      sharedStyle: {
        color: themeUi.semantic.color.background.neutral100
      },
      securitySection: {
        components: {
          saslAuthentication: {
            userName: {
              color: themeUi.semantic.color.text.neutral800,
              backgroundColor: themeUi.semantic.color.background.neutral100
            }
          }
        }
      },
      scalabilitySection: {
        components: {
          hashing: {
            hashingAndThroughput: {
              color: themeUi.semantic.color.text.neutral600
            }
          },
          memory: {
            components: {
              bdbThroughput: {
                throughputValue: {
                  color: themeUi.semantic.color.text.neutral800
                }
              }
            }
          }
        }
      },
      scalabilitySectionPackaging: {
        components: {
          item: {
            color: themeUi.semantic.color.text.neutral700
          }
        }
      },
      generalSection: {
        components: {
          subscriptionName: {
            subscriptionName: {
              borderLeft: themeUi.semantic.color.border.neutral400
            }
          },
          generalDetails: {
            components: {
              memCachedIndication: {
                text: {
                  color: themeUi.semantic.color.text.neutral700
                }
              },
              redisStackIndication: {
                text: {
                  color: themeUi.semantic.color.text.neutral700
                }
              }
            },
            generalSection: {
              backgroundColor: themeUi.semantic.color.background.neutral100
            },
            itemContainer: {
              borderLeft: themeUi.semantic.color.border.neutral400
            },
            label: {
              color: themeUi.semantic.color.text.neutral800
            },
            value: {
              color: themeUi.semantic.color.text.neutral700
            }
          },
          dbNameAndPort: {
            container: {
              backgroundColor: themeUi.semantic.color.background.neutral100
            },
            dbName: {
              color: themeUi.semantic.color.text.neutral800
            }
          }
        }
      },
      durabilitySection: {
        components: {
          activePassiveRedis: {
            components: {
              replicaDrawer: {
                components: {
                  certificateUploadInput: {
                    borderRightColor: themeUi.semantic.color.border.neutral400
                  }
                },
                toggleGroup: {
                  disabledBackgroundColor: themeUi.semantic.color.background.neutral200,
                  stateOff: {
                    backgroundColor: themeUi.semantic.color.background.neutral300,
                    color: themeUi.semantic.color.text.neutral600,
                    borderColor: themeUi.semantic.color.border.neutral400
                  },
                  stateOn: {
                    backgroundColor: themeUi.semantic.color.background.primary200,
                    borderColor: themeUi.semantic.color.border.primary300
                  }
                },
                infoWithPipe: {
                  borderRight: themeUi.semantic.color.border.neutral400
                }
              },
              stopRestartButton: {
                color: themeUi.semantic.color.text.neutral800
              }
            },
            styledTable: {
              borderColor: themeUi.semantic.color.border.neutral400
            },
            modalQuestion: {
              color: themeUi.semantic.color.text.neutral800
            }
          }
        }
      },
      dangerZoneSection: {
        dangerZoneCard: {
          borderBottomColor: themeUi.semantic.color.border.neutral400
        }
      }
    }
  }
};

export default theme;
