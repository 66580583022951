import { SessionLogsTheme } from 'themes/types/themeApp/components/sessionLogs.types';
import { themeUi } from '../../themeUi/themeUi';

const theme: SessionLogsTheme = {
  components: {
    sessionLogsHeader: {
      sessionLogsHeader: {
        headerIconButton: {
          color: themeUi.semantic.color.text.neutral700
        }
      },

      components: {
        clearButton: {
          clearButton: {
            color: themeUi.semantic.color.text.neutral700
          }
        }
      }
    }
  }
};

export default theme;
